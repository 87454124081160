function achatController(
  $translate,
  $state,
  $scope,
  WS,
  $window,
  $parse,
  $compile,
  $timeout,
  $uibModal
) {
  "ngInject";
  const vm = this;
  vm.searchbox = {};
  vm.chargement = {};
  vm.chargement.ch = {};
  vm.product = {};
  vm.filter_stat = {};
  vm.chargement.ch.produits = {};
  vm.chargement.ch.remise_facture = 0;
  vm.chargement.ch.remise_espece = 0;
  vm.chargement.ch.remise_pied = 0;
  vm.codeparams = $state.params.code;

  vm.retenue = 0;
  vm.method_calcule = "cummulable";
  vm.remise_checkbox = false;


  vm.paiement = {};
  vm.paiement.cheques = [];
  vm.paiement.traites = [];

  vm.countBlockCheques = 1;
  vm.countBlockTraites = 1;

  vm.current_date = moment().format('DD/MM/YYYY');
  vm.filter_stat.date_debut = moment().format('YYYY-MM-DD');
  vm.filter_stat.date_fin = moment().format('YYYY-MM-DD');
  vm.filter_line = {};
  vm.filter_line.filter_year = moment().format('YYYY');
  vm.document_print = {};

  vm.focused_index = 0;
  vm.focused_InputName = '';
  vm.focusedInput = (index, inputName) => {
    vm.focused_index = index;
    vm.focused_InputName = inputName;
    console.log(vm.focused_index, vm.focused_InputName)
  }

  vm.list_factures = [];
  vm.retenue_rs = {};
  vm.paiement_multiple = {};
  vm.manageListFactures = (entete) => {
    if (vm.paiement_multiple[entete.code].selected == true) {
      vm.list_factures.push(entete);
    } else {
      vm.list_factures = vm.list_factures.filter(obj => obj.code !== entete.code);
    }
    console.log(vm.list_factures);

    vm.rest_a_payer = 0;
    vm.sum_montant_total_ttc = 0;
    vm.sum_total_ht = 0;
    vm.sum_sous_total_ht = 0;
    vm.sum_remise = 0;
    angular.forEach(vm.list_factures, function (value, key) {
      vm.rest_a_payer += value.net_a_payer ? Number(value.net_a_payer) : 0;
      vm.sum_montant_total_ttc += value.montant_total_ttc ? Number(value.montant_total_ttc) : 0;
      vm.sum_total_ht += value.total_ht ? Number(value.total_ht) : 0;
      vm.sum_sous_total_ht += value.sous_total_ht ? Number(value.sous_total_ht) : 0;
      vm.sum_remise += value.remise ? Number(value.remise) : 0;
    });
    vm.sum_retenue = 0;
    angular.forEach(vm.retenue_rs, function (value, key) {
      if (value.selected) {
        vm.sum_retenue += value.montant_rs ? Number(value.montant_rs) : 0;
      }
    });

    vm.rest_a_payer = vm.rest_a_payer.toFixed(3);
    vm.rest_a_payer = Number(vm.rest_a_payer);

    vm.sum_retenue = vm.sum_retenue.toFixed(3);
    vm.sum_retenue = Number(vm.sum_retenue);
  }

  vm.retiterFacture = (entete) => {

    vm.list_factures = vm.list_factures.filter(obj => obj.code !== entete.code);
    vm.paiement_multiple[entete.code].selected = false;

    // console.log(vm.list_factures);

    vm.rest_a_payer = 0;
    vm.sum_montant_total_ttc = 0;
    vm.sum_total_ht = 0;
    vm.sum_sous_total_ht = 0;
    vm.sum_remise = 0;
    angular.forEach(vm.list_factures, function (value, key) {
      vm.rest_a_payer += value.net_a_payer ? Number(value.net_a_payer) : 0;
      vm.sum_montant_total_ttc += value.montant_total_ttc ? Number(value.montant_total_ttc) : 0;
      vm.sum_total_ht += value.total_ht ? Number(value.total_ht) : 0;
      vm.sum_sous_total_ht += value.sous_total_ht ? Number(value.sous_total_ht) : 0;
      vm.sum_remise += value.remise ? Number(value.remise) : 0;
    });
    vm.sum_retenue = 0;
    angular.forEach(vm.retenue_rs, function (value, key) {
      if (value.selected) {
        vm.sum_retenue += value.montant_rs ? Number(value.montant_rs) : 0;
      }
    });

    vm.rest_a_payer = vm.rest_a_payer.toFixed(3);
    vm.rest_a_payer = Number(vm.rest_a_payer);

    vm.sum_retenue = vm.sum_retenue.toFixed(3);
    vm.sum_retenue = Number(vm.sum_retenue);
  };

  vm.recalculate_rs = (entete) => {
    if (vm.retenue_rs[entete.code].selected) {
      if (entete.fournisseur_code) {
        vm.retenue = _.findWhere(vm.select_frs, {
          code: entete.fournisseur_code,
        }).retenue;
      }
      vm.retenue_rs[entete.code].montant_rs = entete.montant_total_ttc * (vm.retenue / 100);
      // vm.chargement.ch.net_a_payer = vm.chargement.ch.montant_total_ttc - vm.chargement.ch.montant_rs;
    } else {
      vm.retenue_rs[entete.code].montant_rs = 0;
      //vm.chargement.ch.net_a_payer = vm.chargement.ch.montant_total_ttc;
    }

    // recalculate total after change rs
    vm.rest_a_payer = 0;
    vm.sum_montant_total_ttc = 0;
    vm.sum_total_ht = 0;
    vm.sum_sous_total_ht = 0;
    vm.sum_remise = 0;
    angular.forEach(vm.list_factures, function (value, key) {
      vm.rest_a_payer += value.net_a_payer ? Number(value.net_a_payer) : 0;
      vm.sum_montant_total_ttc += value.montant_total_ttc ? Number(value.montant_total_ttc) : 0;
      vm.sum_total_ht += value.total_ht ? Number(value.total_ht) : 0;
      vm.sum_sous_total_ht += value.sous_total_ht ? Number(value.sous_total_ht) : 0;
      vm.sum_remise += value.remise ? Number(value.remise) : 0;
    });
    vm.sum_retenue = 0;
    angular.forEach(vm.retenue_rs, function (value, key) {
      if (value.selected) {
        vm.sum_retenue += value.montant_rs ? Number(value.montant_rs) : 0;
      }
    });

    vm.rest_a_payer = vm.rest_a_payer.toFixed(3);
    vm.rest_a_payer = Number(vm.rest_a_payer);

    //vm.sum_retenue
    vm.sum_retenue = vm.sum_retenue.toFixed(3);
    vm.sum_retenue = Number(vm.sum_retenue);

  };


  vm.getByPayement_code = (paiement_code) => {
    vm.searchbox.paiement_code = paiement_code;
    vm.searchbox.fournisseur_code = "";
    vm.searchbox.date_debut = "";
    vm.searchbox.date_fin = "";
    vm.get();
  }

  $(document).ready(function () {
    $(document).on("keydown", function (event) {
      var keyCode = event.which;
      //go to bottom
      if (keyCode == 9 || keyCode == 13 || keyCode == 40) {
        event.preventDefault();
        if (Object.keys(vm.chargement.ch.produits).length > vm.focused_index) {
          vm.focused_index = vm.focused_index + 1;
          var className = `.input-${vm.focused_InputName}-${vm.focused_index}`;
          console.log(vm.focused_index, className)
          $(className).focus();
        }

      }

      //go to top
      if (keyCode == 38) {
        event.preventDefault();
        if (vm.focused_index > 0) {
          vm.focused_index = vm.focused_index - 1;
          var className = `.input-${vm.focused_InputName}-${vm.focused_index}`;
          $(className).focus();
        }
      }

      if (keyCode == 39) {
        event.preventDefault();
        switch (vm.focused_InputName) {
          case 'prix_achat_ht':
            vm.focused_InputName = 'prix_achat_ttc'
            break;
          case 'prix_achat_ttc':
            vm.focused_InputName = 'remise'
            break;
          case 'remise':
            vm.focused_InputName = 'remise_promo'
            break;
          case 'remise_promo':
            vm.focused_InputName = 'remise_dc'
            break;
          case 'remise_dc':
            vm.focused_InputName = 'quantite'
            break;
          case 'quantite':
            vm.focused_InputName = 'prix_achat_ht'
            break;
          default:
            console.log(`Sorry, we are out of ${vm.focused_InputName}.`);
        }
        var className = `.input-${vm.focused_InputName}-${vm.focused_index}`;
        $(className).focus();
      }

      if (keyCode == 37) {
        event.preventDefault();
        switch (vm.focused_InputName) {
          case 'prix_achat_ht':
            vm.focused_InputName = 'quantite'
            break;
          case 'prix_achat_ttc':
            vm.focused_InputName = 'prix_achat_ht'
            break;
          case 'remise':
            vm.focused_InputName = 'prix_achat_ttc'
            break;
          case 'remise_promo':
            vm.focused_InputName = 'remise'
            break;
          case 'remise_dc':
            vm.focused_InputName = 'remise_promo'
            break;
          case 'quantite':
            vm.focused_InputName = 'remise_dc'
            break;
          default:
            console.log(`Sorry, we are out of ${vm.focused_InputName}.`);
        }
        var className = `.input-${vm.focused_InputName}-${vm.focused_index}`;
        $(className).focus();
      }
      console.log(event.type + ": " + event.which, vm.focused_InputName);

    });
  })


  vm.changeRemise = (valeur_remise) => {
    // console.log(vm.remise_checkbox)
    if (vm.remise_checkbox) {
      _.each(vm.chargement["ch"]["produits"], (element, index) => {
        vm.chargement["ch"]["produits"][index].remise = valeur_remise;
      });
    }
  };
  vm.selectFournisseurs = function () {
    WS.get("fournisseurs/selectFournisseursWithCode")
      .then(function (response) {
        if (response.status == 200) {
          vm.select_frs = response.data;
          $scope.$apply();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.default_banque_id = "";


  vm.selectBanques = function () {
    WS.get("compte_banques/selectBanques")
      .then(function (response) {
        if (response.status == 200) {
          vm.select_banques = response.data;
          _.each(vm.select_banques, element => {
            if (element.principale == 1) {

              vm.paiement.cheques[0] = {};
              vm.paiement.cheques[0].banque_id = element.id;
              vm.paiement.cheques[0].banque_cheque = element.banque;

              vm.paiement.traites[0] = {};

              vm.paiement.traites[0].banque_id = element.id;
              vm.paiement.traites[0].banque_traite = element.banque;



            }
          })

          $scope.$apply();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectedBanque = (index, banque_id, type) => {

    _.each(vm.select_banques, element => {
      if (element.id == banque_id) {
        if (type == 'cheque') {
          vm.paiement.cheques[index].banque_cheque = element.banque;
        }
        if (type == 'traite') {
          vm.paiement.traites[index].banque_traite = element.banque;
        }
      }

    })

  }

  vm.total_des_paiements = 0;
  vm.getOldPaiement = () => {
    vm.total_des_paiements = 0;
    vm.sum_retenue = 0;
    if (vm.searchbox.paiement_code) {

      WS.getData("mouvement_fournisseurs/getOldPaiement", { paiement_code: vm.searchbox.paiement_code })
        .then(function (response) {
          if (response.status == 200) {
            vm.old_paiements = response.data;
            // console.log(vm.old_paiements);
            _.each(vm.old_paiements, (element, index) => {
              //-1 * element.montant
              vm.total_des_paiements += (element.type_montant == 'RS') ? 0 : element.montant;
              if (element.type_montant == 'RS') {
                vm.retenue_rs[element.entete_achat_code].selected = true;
                vm.retenue_rs[element.entete_achat_code].montant_rs = element.montant;
                vm.sum_retenue += vm.retenue_rs[element.entete_achat_code].montant_rs ? Number(vm.retenue_rs[element.entete_achat_code].montant_rs) : 0;

              }
            });

            console.log('vm.sum_retenue 1*', vm.sum_retenue)
            $scope.$apply();
          } else {
          }
        })
        .then(null, function (error) {
          console.log(error);
        });
    }
  }


  vm.get = function (page = 1, per_page = 15) {

    vm.p_sum_sous_total_ht = 0;
    vm.p_sum_total_ht = 0;
    vm.p_sum_total_ht = 0;
    vm.p_sum_montant_total_ttc = 0;
    vm.p_sum_montant_rs = 0;
    vm.p_sum_net_a_payer = 0;
    vm.p_sum_paiement = 0;

    var data = {
      per_page,
      page,
      // user_code: vm.searchbox.user_code,
      // depot_code: vm.searchbox.depot_code,
      date_debut: vm.searchbox.date_debut,
      date_fin: vm.searchbox.date_fin,
      orderby: vm.searchbox.orderby,
      paiement_code: vm.searchbox.paiement_code
    };
    WS.getData("entete_achat", data)
      .then(function (response) {
        if (response.status == 200) {
          vm.entetes = response.data.entete.data;
          vm.current_page = response.data.entete.current_page;
          vm.previous = vm.current_page - 1;
          vm.next = vm.current_page + 1;
          vm.from = response.data.entete.form;
          vm.last_page = response.data.entete.last_page;
          vm.next_page_url = response.data.entete.next_page_url;
          vm.per_page = response.data.entete.per_page.toString();
          vm.prev_page_url = response.data.entete.prev_page_url;
          vm.to = response.data.entete.to;
          vm.total = response.data.entete.total;
          vm.paginate = [];
          for (var i = 1; i <= vm.last_page; i++) {
            vm.paginate.push(i);
          }
          if (vm.searchbox.paiement_code) {
            vm.list_factures = [];
            vm.p_sum_sous_total_ht = 0;
            vm.p_sum_total_ht = 0;
            vm.p_sum_total_ht = 0;
            vm.p_sum_montant_total_ttc = 0;
            vm.p_sum_montant_rs = 0;
            vm.p_sum_net_a_payer = 0;
            vm.p_sum_paiement = 0;
            _.each(vm.entetes, (element, index) => {
              vm.p_sum_sous_total_ht += element.sous_total_ht;
              vm.p_sum_total_ht += element.total_ht;
              vm.p_sum_total_ht += element.total_ht;
              vm.p_sum_montant_total_ttc += element.montant_total_ttc;
              vm.p_sum_montant_rs += element.montant_rs;
              vm.p_sum_net_a_payer += element.net_a_payer;

              //vm.list_factures.push(element);
              if (vm.paiement_multiple[element.code]) {
                vm.paiement_multiple[element.code].selected = true;
                vm.paiement_multiple[element.code].disabled = true;

              } else {
                vm.paiement_multiple[element.code] = {};
                vm.paiement_multiple[element.code].selected = true;
                vm.paiement_multiple[element.code].disabled = true;
              }
              vm.manageListFactures(element);
              //vm.recalculate_rs(element);
            });
            vm.p_sum_paiement = response.data.total_paiement.sum_paiement;
          }
          $scope.$apply();

          vm.selectBanques();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.checkpaginate = function (current, page, lastpage) {
    if (page > current + 3 || page < current - 3) {
      return false;
    } else {
      return true;
    }
  };
  $(document).ready(function () {
    $(".sf_promo_gr_debut").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.searchbox.date_debut = date;
        $scope.$apply();
      },
    });

    $(".sf_promo_gr_fin").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.searchbox.date_fin = date;
        $scope.$apply();
      },
    });

    $(".f_date_debut").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.filter_stat.date_debut = date;
        $scope.$apply();
      },
    });

    $(".f_date_fin").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.filter_stat.date_fin = date;
        $scope.$apply();
      },
    });

    $(".f_filter_year").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.filter_line.filter_year = date;
        $scope.$apply();
      },
    });

  });


  vm.reinitdates = () => {

    $(document).ready(function () {

      $(".f_date_debut").datepicker({
        autoClose: true,
        onSelect: function (date) {
          vm.filter_stat.date_debut = date;
          $scope.$apply();
        },
      });

      $(".f_date_fin").datepicker({
        autoClose: true,
        onSelect: function (date) {
          vm.filter_stat.date_fin = date;
          $scope.$apply();
        },
      });

      $(".f_filter_year").datepicker({
        autoClose: true,
        onSelect: function (date) {
          vm.filter_line.filter_year = date;
          $scope.$apply();
        },
      });
    });
  }
  $scope.moveCSV = function (element) {
    var reader = new FileReader();
    reader.onload = function (event) {
      vm.commande.file = event.target.result;
      $scope.$apply(function () {
        vm.files = element.files;
      });
    };
    reader.readAsDataURL(element.files[0]);
  };

  vm.storeEntete = function () {
    if (vm.files) {
      vm.commande.file = vm.files[0];
    } else {
      vm.commande.file = null;
    }
    WS.postwithimg("entete_achat/storeEntete", vm.commande, "POST").then(
      function (response) {
        if (response.data.success) {
          //vm.entetes.splice(0, 0, response.data.entete);
          $scope.$apply();
          swal(
            $translate.instant("Succés"),
            $translate.instant("Commande ajoutée avec succés"),
            "success"
          ).then(function () {
            $window.location.reload();
          });
        } else {
          swal(
            $translate.instant("Attention"),
            $translate.instant(response.data.message),
            "warning"
          );
        }
      },
      function (error) {
        console.log(error);
        swal(
          $translate.instant("Attention"),
          $translate.instant("erreur d'insertion!"),
          "error"
        );
      }
    );
  };

  /**
   * *******************************************new page achat**********************************************
   */

  vm.auth_chargement = "1";
  vm.chargement_apply_filter = { chargement: vm.auth_chargement };
  vm.applyfilter = function () {
    if (vm.chargement.ch.type != 'avoir' && vm.chargement.ch.type != 'avoir_financier') {
      if (vm.auth_chargement == "0") {
        vm.chargement_apply_filter = "";
        if (vm.chargement.ch.fournisseur_code) {
          vm.chargement_apply_filter = { fournisseur_code: (vm.chargement.ch.fournisseur_code) };
        }
      } else {
        vm.chargement_apply_filter = { chargement: vm.auth_chargement };
        if (vm.chargement.ch.fournisseur_code) {
          vm.chargement_apply_filter = { chargement: vm.auth_chargement, fournisseur_code: (vm.chargement.ch.fournisseur_code) };
        }

      }
    }


  };

  vm.getproduits = function () {

    vm.chargement = {};
    vm.chargement.ch = {};
    vm.chargement.ch.produits = {};
    vm.chargement.ch.remise_facture = 0;
    vm.chargement.ch.remise_espece = 0;
    vm.chargement.ch.remise_pied = 0;
    vm.codeparams = $state.params.code;
    vm.btn_save = false;
    WS.get("mouvements/names")
      .then(function (response) {
        vm.chargement.ch.entete_achat_code = $state.params.code;
        vm.chargement.ch.code = $state.params.code;
        vm.produits = response.data.produits;
        vm.loading = false;
        vm.chargement.ch.remise_facture = 0;
        vm.chargement.ch.remise_espece = 0;
        vm.chargement.ch.remise_pied = 0;
        vm.btn_save = false;
        // traitement
        _.each(vm.produits, (element, index) => {
          vm.chargement["ch"]["produits"][element.id] = {};
          vm.chargement["ch"]["produits"][element.id]["id"] = element.id;
          vm.chargement["ch"]["produits"][element.id]["code"] = element.code;
          vm.chargement["ch"]["produits"][element.id]["produit_code"] =
            element.code;
          vm.chargement["ch"]["produits"][element.id]["code_a_barre"] =
            element.code_a_barre;
          vm.chargement["ch"]["produits"][element.id]["libelle"] =
            element.libelle;
          vm.chargement["ch"]["produits"][element.id]["colisage"] =
            element.colisage;
          vm.chargement["ch"]["produits"][element.id]["tva"] = element.tva;
          vm.chargement["ch"]["produits"][element.id]["prix_achat_ht"] =
            element.prix_achat_ht;
          vm.chargement["ch"]["produits"][element.id]["prix_achat_ttc"] =
            element.prix_achat_ttc;
          vm.chargement["ch"]["produits"][element.id]["prix_ttc"] =
            element.prix_ttc;
          vm.chargement["ch"]["produits"][element.id]["prix_ht"] =
            element.prix_ht;

          vm.chargement["ch"]["produits"][element.id]["ordre"] = element.ordre;
          vm.chargement["ch"]["produits"][element.id]["chargement"] =
            element.chargement;
          vm.chargement["ch"]["produits"][element.id]["sousfamille_code"] =
            element.sousfamille_code;
          vm.chargement["ch"]["produits"][element.id]["fournisseur_code"] =
            element.fournisseur_code;
          vm.chargement["ch"]["produits"][element.id]["famille_code"] =
            element.famille_code;
          vm.chargement["ch"]["produits"][element.id]["gamme_code"] =
            element.gamme_code;
          vm.chargement["ch"]["produits"][element.id]["stk_quantite"] =
            element.stk_quantite;
          vm.chargement["ch"]["produits"][element.id]["stk_casse"] =
            element.stk_casse;
          vm.chargement["ch"]["produits"][element.id]["stk_dlc"] =
            element.stk_dlc;
          vm.chargement["ch"]["produits"][element.id]["hors_box"] =
            element.hors_box;
          //remises
          vm.chargement["ch"]["produits"][element.id]["remise"] = 0;
          vm.chargement["ch"]["produits"][element.id]["remise_promo"] = 0;
          vm.chargement["ch"]["produits"][element.id]["remise_dc"] = 0;
          vm.chargement["ch"]["produits"][element.id]["remise_1"] = null;
          vm.chargement["ch"]["produits"][element.id]["remise_2"] = null;
          vm.chargement["ch"]["produits"][element.id]["remise_3"] = null;

          vm.chargement["ch"]["produits"][element.id]["dlc"] = null;
          vm.chargement["ch"]["produits"][element.id]["num_lot"] = null;

          vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ht"] =
            vm.chargement["ch"]["produits"][element.id]["prix_achat_ht"] -
            (vm.chargement["ch"]["produits"][element.id]["prix_achat_ht"] *
              (Number(vm.chargement["ch"]["produits"][element.id]["remise"]) +
                Number(vm.chargement["ch"]["produits"][element.id]["remise_promo"]) +
                Number(vm.chargement["ch"]["produits"][element.id]["remise_dc"]) +
                Number(vm.chargement["ch"]["produits"][element.id]["remise_1"]) +
                Number(vm.chargement["ch"]["produits"][element.id]["remise_2"]) +
                Number(vm.chargement["ch"]["produits"][element.id]["remise_3"]) +
                Number(vm.chargement.ch.remise_facture) +
                Number(vm.chargement.ch.remise_espece) +
                Number(vm.chargement.ch.remise_pied))) / 100;
          vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ttc"] =
            vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ht"] +
            (vm.chargement["ch"]["produits"][element.id]["tva"] / 100) *
            vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ht"];

          vm.chargement["ch"]["produits"][element.id]["total_net_ht"] = null;
          vm.chargement["ch"]["produits"][element.id]["total_net_ttc"] = null;
          vm.chargement["ch"]["produits"][element.id]["sous_total_ht"] = null;

          // console.log('-------------------------------------------------------------------------------')
          // console.log(vm.chargement['ch']['produits'][element.id]);
        });

        $scope.$apply();
        if ($state.params.code) {
          vm.getCurrentAchat(); //to get old quantites stored;
        } else {
          // $('.table').DataTable({
          //     "language": DatatableLang,
          //     "paging": false,
          //     'searching' : false
          // });
        }

      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.getListProds = function () {

    vm.chargement = {};
    vm.chargement.ch = {};
    vm.chargement.ch.produits = {};
    vm.chargement.ch.remise_facture = 0;
    vm.chargement.ch.remise_espece = 0;
    vm.chargement.ch.remise_pied = 0;
    vm.codeparams = $state.params.code;
    vm.btn_save = false;
    WS.get("mouvements/names")
      .then(function (response) {
        vm.chargement.ch.entete_achat_code = $state.params.code;
        vm.chargement.ch.code = $state.params.code;
        vm.produits = response.data.produits;
        vm.loading = false;
        vm.chargement.ch.remise_facture = 0;
        vm.chargement.ch.remise_espece = 0;
        vm.chargement.ch.remise_pied = 0;
        vm.btn_save = false;


        $scope.$apply();
        if ($state.params.code) {
          vm.getCurrentAchatOneProds(); //to get old quantites stored;
        } else {
          // $('.table').DataTable({
          //     "language": DatatableLang,
          //     "paging": false,
          //     'searching' : false
          // });
        }

      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.addOneProd = () => {

    var tab = _.filter(vm.produits, function (item) {
      return item.code == vm.selected_prod
    });

    _.each(tab, (element, index) => {
      vm.chargement["ch"]["produits"][element.id] = {};
      vm.chargement["ch"]["produits"][element.id]["id"] = element.id;
      vm.chargement["ch"]["produits"][element.id]["code"] = element.code;
      vm.chargement["ch"]["produits"][element.id]["produit_code"] = element.code;
      vm.chargement["ch"]["produits"][element.id]["code_a_barre"] = element.code_a_barre;
      vm.chargement["ch"]["produits"][element.id]["libelle"] = element.libelle;
      vm.chargement["ch"]["produits"][element.id]["colisage"] = element.colisage;
      vm.chargement["ch"]["produits"][element.id]["tva"] = element.tva;
      vm.chargement["ch"]["produits"][element.id]["prix_achat_ht"] = element.prix_achat_ht;
      vm.chargement["ch"]["produits"][element.id]["prix_achat_ttc"] = element.prix_achat_ttc;
      vm.chargement["ch"]["produits"][element.id]["prix_ttc"] = element.prix_ttc;
      vm.chargement["ch"]["produits"][element.id]["prix_ht"] = element.prix_ht;

      vm.chargement["ch"]["produits"][element.id]["ordre"] = element.ordre;
      vm.chargement["ch"]["produits"][element.id]["chargement"] = element.chargement;
      vm.chargement["ch"]["produits"][element.id]["sousfamille_code"] = element.sousfamille_code;
      vm.chargement["ch"]["produits"][element.id]["fournisseur_code"] = element.fournisseur_code;
      vm.chargement["ch"]["produits"][element.id]["famille_code"] = element.famille_code;
      vm.chargement["ch"]["produits"][element.id]["gamme_code"] = element.gamme_code;
      vm.chargement["ch"]["produits"][element.id]["stk_quantite"] = element.stk_quantite;
      vm.chargement["ch"]["produits"][element.id]["stk_casse"] = element.stk_casse;
      vm.chargement["ch"]["produits"][element.id]["stk_dlc"] = element.stk_dlc;
      vm.chargement["ch"]["produits"][element.id]["hors_box"] = element.hors_box;
      //remises
      vm.chargement["ch"]["produits"][element.id]["remise"] = 0;
      vm.chargement["ch"]["produits"][element.id]["remise_promo"] = 0;
      vm.chargement["ch"]["produits"][element.id]["remise_dc"] = 0;
      vm.chargement["ch"]["produits"][element.id]["remise_1"] = null;
      vm.chargement["ch"]["produits"][element.id]["remise_2"] = null;
      vm.chargement["ch"]["produits"][element.id]["remise_3"] = null;

      vm.chargement["ch"]["produits"][element.id]["dlc"] = null;
      vm.chargement["ch"]["produits"][element.id]["num_lot"] = null;

      vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ht"] =
        vm.chargement["ch"]["produits"][element.id]["prix_achat_ht"] -
        (vm.chargement["ch"]["produits"][element.id]["prix_achat_ht"] *
          (Number(vm.chargement["ch"]["produits"][element.id]["remise"]) +
            Number(vm.chargement["ch"]["produits"][element.id]["remise_promo"]) +
            Number(vm.chargement["ch"]["produits"][element.id]["remise_dc"]) +
            Number(vm.chargement["ch"]["produits"][element.id]["remise_1"]) +
            Number(vm.chargement["ch"]["produits"][element.id]["remise_2"]) +
            Number(vm.chargement["ch"]["produits"][element.id]["remise_3"]) +
            Number(vm.chargement.ch.remise_facture) +
            Number(vm.chargement.ch.remise_espece) +
            Number(vm.chargement.ch.remise_pied))) / 100;
      vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ttc"] =
        vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ht"] +
        (vm.chargement["ch"]["produits"][element.id]["tva"] / 100) *
        vm.chargement["ch"]["produits"][element.id]["prix_achat_net_ht"];

      vm.chargement["ch"]["produits"][element.id]["total_net_ht"] = null;
      vm.chargement["ch"]["produits"][element.id]["total_net_ttc"] = null;
      vm.chargement["ch"]["produits"][element.id]["sous_total_ht"] = null;

      // console.log('-------------------------------------------------------------------------------')
      // console.log(vm.chargement['ch']['produits'][element.id]);
    });
  }



  vm.getCurrentAchatOneProds = function () {
    WS.getData("entete_achat/getCurrentAchat", { code: $state.params.code })
      .then(function (response) {
        if (response.data.length > 0) {


          response.data.forEach((ligne) => {
            vm.selected_prod = ligne.produit_code;
            vm.addOneProd();

            vm.chargement["ch"]["produits"][ligne.id]["quantite"] = ligne.quantite;

            vm.chargement["ch"]["produits"][ligne.id]["prix_achat_ht"] = ligne.prix_achat_ht;
            vm.chargement["ch"]["produits"][ligne.id]["prix_achat_ttc"] = ligne.prix_achat_ttc;
            vm.chargement["ch"]["produits"][ligne.id]["prix_ht"] = ligne.prix_ht;
            vm.chargement["ch"]["produits"][ligne.id]["prix_ttc"] = ligne.prix_ttc;

            vm.chargement["ch"]["produits"][ligne.id]["prix_achat_net_ht"] = ligne.prix_achat_net_ht;
            vm.chargement["ch"]["produits"][ligne.id]["prix_achat_net_ttc"] = ligne.prix_achat_net_ttc;

            vm.chargement["ch"]["produits"][ligne.id]["total_net_ht"] = ligne.total_net_ht;
            vm.chargement["ch"]["produits"][ligne.id]["total_net_ttc"] = ligne.total_net_ttc;

            vm.chargement["ch"]["produits"][ligne.id]["remise"] = ligne.remise;
            vm.chargement["ch"]["produits"][ligne.id]["remise_promo"] = ligne.remise_promo;
            vm.chargement["ch"]["produits"][ligne.id]["remise_dc"] = ligne.remise_dc;
            vm.chargement["ch"]["produits"][ligne.id]["remise_1"] = ligne.remise_1;
            vm.chargement["ch"]["produits"][ligne.id]["remise_2"] = ligne.remise_2;
            vm.chargement["ch"]["produits"][ligne.id]["remise_3"] = ligne.remise_3;

            vm.chargement["ch"]["produits"][ligne.id]["tva"] = ligne.tva;

            vm.chargement["ch"]["produits"][ligne.id]["dlc"] = ligne.dlc;
            vm.chargement["ch"]["produits"][ligne.id]["num_lot"] = ligne.num_lot;

            vm.chargement["ch"]["produits"][ligne.id]["sous_total_ht"] = ligne.quantite * ligne.prix_achat_ht;

            //vm.changeValue(id)

          });

          vm.calculeTotal();

        }
        $scope.$apply();
        // $('.table').DataTable({
        //     "language": DatatableLang,
        //     "paging": false,
        //     'searching' : false
        // });
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.removeProd = (id) => {
    delete vm.chargement["ch"]["produits"][id];
    vm.calculeTotal();
  }

  vm.changeValue = (id) => {
    //console.log('started function changeValue calcul total remise net', vm.method_calcule, id,vm.chargement['ch']['produits'][id] );
    if (vm.method_calcule == "cummulable") {
      //prix_achat_net_ht=prix_achat_brut-
      //    (prix_achat_brut*((remise+remise_promo+ remise_1+remise_2+remise_3+remise_facture+remise_espece+remise_pied))/100))-
      vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"] =
        Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) -
        (Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) *
          (Number(vm.chargement["ch"]["produits"][id]["remise"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_promo"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_dc"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_1"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_2"]) +
            Number(vm.chargement["ch"]["produits"][id]["remise_3"]) +
            Number(vm.chargement.ch.remise_facture) +
            Number(vm.chargement.ch.remise_espece) +
            Number(vm.chargement.ch.remise_pied))) /
        100;
    } else {
      //prix_achat_net_ht_fixe=prix_achat_brut- (prix_achat_brut*((remise+remise_promo+ remise_1+remise_2+remise_3/100))
      //prix_achat_net_ht= prix_achat_net_ht_fixe- (prix_achat_net_ht_fixe*((remise_facture+remise_espece+remise_pied))/100))-
      vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"] =
        Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) -
        Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) *
        ((Number(vm.chargement["ch"]["produits"][id]["remise"]) +
          Number(vm.chargement["ch"]["produits"][id]["remise_promo"]) +
          Number(vm.chargement["ch"]["produits"][id]["remise_dc"]) +
          Number(vm.chargement["ch"]["produits"][id]["remise_1"]) +
          Number(vm.chargement["ch"]["produits"][id]["remise_2"]) +
          Number(vm.chargement["ch"]["produits"][id]["remise_3"])) /
          100);

      vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"] =
        Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]) -
        Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]) *
        ((vm.chargement.ch.remise_facture +
          vm.chargement.ch.remise_espece +
          vm.chargement.ch.remise_pied) /
          100);
    }

    vm.chargement["ch"]["produits"][id]["prix_achat_net_ttc"] =
      Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]) +
      (Number(vm.chargement["ch"]["produits"][id]["tva"]) / 100) *
      Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]);

    if (vm.chargement.ch.colisage == false) {


      vm.chargement["ch"]["produits"][id]["total_net_ht"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
        ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"])
        : null;
      vm.chargement["ch"]["produits"][id]["total_net_ttc"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
        ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ttc"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"])
        : null;

      /**
       * sous_total_ht
       */

      vm.chargement["ch"]["produits"][id]["sous_total_ht"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
        ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"])
        : null;

      /**
       * remise
       */
      vm.chargement["ch"]["produits"][id]["montant_remise"] =
        vm.chargement["ch"]["produits"][id]["sous_total_ht"] -
        vm.chargement["ch"]["produits"][id]["total_net_ht"];

      //calcul sum_net_ttc et sum_net_ht ,sum_ttc, sum_ht
      vm.calculeTotal();
    } else {
      vm.chargement["ch"]["produits"][id]["total_net_ht"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
        ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ht"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"]) * Number(vm.chargement["ch"]["produits"][id]["colisage"])
        : null;
      vm.chargement["ch"]["produits"][id]["total_net_ttc"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
        ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_net_ttc"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"]) * Number(vm.chargement["ch"]["produits"][id]["colisage"])
        : null;

      /**
       * sous_total_ht
       */

      vm.chargement["ch"]["produits"][id]["sous_total_ht"] = Number(vm.chargement["ch"]["produits"][id]["quantite"])
        ? Number(vm.chargement["ch"]["produits"][id]["prix_achat_ht"]) * Number(vm.chargement["ch"]["produits"][id]["quantite"]) * Number(vm.chargement["ch"]["produits"][id]["colisage"])
        : null;

      /**
       * remise
       */
      vm.chargement["ch"]["produits"][id]["montant_remise"] =
        vm.chargement["ch"]["produits"][id]["sous_total_ht"] -
        vm.chargement["ch"]["produits"][id]["total_net_ht"];

      //calcul sum_net_ttc et sum_net_ht ,sum_ttc, sum_ht
      vm.calculeTotal();
    }

    if (vm.chargement["ch"]["produits"][id]["code"] == 'CAISSE' || 'PALETTE' || 'ga') {
      vm.getCaissePaletteId();
    }
  };

  vm.getCaissePaletteId = () => {

    /**
     * get caisses
     */
    var caisse = _.find(vm.produits, function (item) { //\\
      return (item.code == 'CAISSE' || item.code.toLowerCase() == 'ga'); //  \\
    }); //    \\
    if (caisse) {
      vm.nbr_caisses = vm.chargement.ch.produits[caisse.id].quantite;
    }

    /**
     * get Palette
     */
    var palette = _.find(vm.produits, function (item) { //\\
      return (item.code == 'PALETTE'); //  \\
    }); //    \\
    if (palette) {
      vm.nbr_palettes = vm.chargement.ch.produits[palette.id].quantite;
    }

  }

  vm.updateCaissePalette = (code) => {

    if (code == 'CAISSE') {
      var caisse = _.find(vm.produits, function (item) { //\\
        return (item.code == 'CAISSE' || item.code.toLowerCase() == 'ga'); //  \\
      }); //    \\
      if (caisse) {
        vm.chargement.ch.produits[caisse.id].quantite = vm.nbr_caisses;
      }
    }

    if (code == 'PALETTE') {
      var palette = _.find(vm.produits, function (item) { //\\
        return (item.code == 'PALETTE'); //  \\
      }); //    \\
      if (palette) {
        vm.chargement.ch.produits[palette.id].quantite = vm.nbr_palettes;
      }
    }
  }

  vm.calculeTotal = () => {
    //console.log('obj', vm.chargement['ch']['produits'])

    vm.chargement.ch.montant_total_ttc =
      _.reduce(
        vm.chargement["ch"]["produits"],
        function (memo, num) {
          return memo + num.total_net_ttc;
        },
        0
      ) + vm.chargement.ch.timbre;
    vm.chargement.ch.total_ht = _.reduce(
      vm.chargement["ch"]["produits"],
      function (memo, num) {
        return memo + num.total_net_ht;
      },
      0
    );

    vm.chargement.ch.total_tva =
      vm.chargement.ch.montant_total_ttc -
      vm.chargement.ch.timbre -
      vm.chargement.ch.total_ht;

    // sous_total_ht
    vm.chargement.ch.sous_total_ht = _.reduce(
      vm.chargement["ch"]["produits"],
      function (memo, num) {
        return memo + num.sous_total_ht;
      },
      0
    );
    vm.chargement.ch.remise =
      vm.chargement.ch.sous_total_ht - vm.chargement.ch.total_ht;

    // retenue & net_a_payer
    if (vm.chargement.ch.calcule_rs) {
      if (vm.chargement.ch.fournisseur_code) {
        vm.retenue = _.findWhere(vm.select_frs, {
          code: vm.chargement.ch.fournisseur_code,
        }).retenue;
      }
      vm.chargement.ch.montant_rs =
        vm.chargement.ch.montant_total_ttc * (vm.retenue / 100);
      vm.chargement.ch.net_a_payer =
        vm.chargement.ch.montant_total_ttc - vm.chargement.ch.montant_rs;
    } else {
      vm.chargement.ch.montant_rs = 0;
      vm.chargement.ch.net_a_payer = vm.chargement.ch.montant_total_ttc;
    }



    //calcule nbr reference nbr caisses nbr palette //
    vm.calculeRef();
  };

  vm.calculeRef = () => {
    //vm.nbr_caisses = 0;
    vm.nbr_references = 0;
    // vm.nbr_palettes = 0;

    _.each(vm.chargement.ch.produits, (element, index) => {

      var quantite = Number(element.quantite);

      if (vm.chargement.ch.colisage == false) {
        //vm.nbr_caisses += _.isNumber(quantite) && !_.isNaN(quantite) ? Number(quantite) / Number(element.colisage) : 0;
        vm.nbr_references += _.isNumber(quantite) && !_.isNaN(quantite) ? 1 : 0;
      } else {
        //vm.nbr_caisses += _.isNumber(quantite) && !_.isNaN(quantite) ? Number(quantite) : 0;
        vm.nbr_references += _.isNumber(quantite) && !_.isNaN(quantite) ? 1 : 0;
      }

    });
    //vm.nbr_caisses = vm.nbr_caisses.toFixed(0);

  }

  $scope.$watch("vm.method_calcule", function (value) {
    _.each(vm.chargement["ch"]["produits"], (element) =>
      vm.changeValue(element.id)
    );
  });


  $scope.$watch("vm.chargement.ch.colisage", function (value) {

    _.each(vm.chargement["ch"]["produits"], (element) => {
      vm.changeValue(element.id)
      vm.calculeRef()
    });
  });

  $scope.$watch("vm.chargement.ch.remise_facture", function (value) {
    console.log(
      "vm.chargement.ch.remise_facture",
      vm.chargement.ch.remise_facture
    );
    _.each(vm.chargement["ch"]["produits"], (element) =>
      vm.changeValue(element.id)
    );
  });
  $scope.$watch("vm.chargement.ch.remise_espece", function (value) {
    console.log(
      "vm.chargement.ch.remise_espece",
      vm.chargement.ch.remise_espece
    );
    _.each(vm.chargement["ch"]["produits"], (element) =>
      vm.changeValue(element.id)
    );
  });
  $scope.$watch("vm.chargement.ch.remise_pied", function (value) {
    console.log(
      "vm.chargement.ch.remise_pied",
      value,
      vm.chargement.ch.remise_pied
    );
    _.each(vm.chargement["ch"]["produits"], (element) =>
      vm.changeValue(element.id)
    );
  });

  // vm.select_frs

  $scope.$watch("vm.chargement.ch.fournisseur_code", function (value) {
    if (vm.chargement.ch.calcule_rs) {
      if (vm.chargement.ch.fournisseur_code) {
        vm.retenue = _.findWhere(vm.select_frs, {
          code: vm.chargement.ch.fournisseur_code,
        }).retenue;
      }
      vm.chargement.ch.montant_rs =
        vm.chargement.ch.montant_total_ttc * (vm.retenue / 100);
      vm.chargement.ch.net_a_payer =
        vm.chargement.ch.montant_total_ttc - vm.chargement.ch.montant_rs;
    } else {
      vm.chargement.ch.montant_rs = 0;
      vm.chargement.ch.net_a_payer = vm.chargement.ch.montant_total_ttc;
    }
  });

  vm.recalculateRs = () => {
    if (vm.chargement.ch.calcule_rs) {
      if (vm.chargement.ch.fournisseur_code) {
        vm.retenue = _.findWhere(vm.select_frs, {
          code: vm.chargement.ch.fournisseur_code,
        }).retenue;
      }
      vm.chargement.ch.montant_rs =
        vm.chargement.ch.montant_total_ttc * (vm.retenue / 100);
      vm.chargement.ch.net_a_payer =
        vm.chargement.ch.montant_total_ttc - vm.chargement.ch.montant_rs;
    } else {
      vm.chargement.ch.montant_rs = 0;
      vm.chargement.ch.net_a_payer = vm.chargement.ch.montant_total_ttc;
    }
  };



  $scope.$watch("vm.chargement.ch.soussociete_code", function (value) {
    if (vm.chargement.ch.soussociete_code) {
      var obj = _.findWhere(vm.soussocietes, {
        code: vm.chargement.ch.soussociete_code,
      });

      vm.chargement.ch.timbre = (obj) ? obj.timbre : 0;
    }
  });

  vm.addLigne = (id) => {
    console.log(id);
    const tr_id = `tr_prod_${id}`;
    const td_id = `td_prod_${id}`;
  };

  vm.getCurrentAchat = function () {
    WS.getData("entete_achat/getCurrentAchat", { code: $state.params.code })
      .then(function (response) {
        if (response.data.length > 0) {
          _.each(vm.chargement["ch"]["produits"], (element, index) => {
            console.log(element);
            response.data.forEach((ligne) => {
              if (ligne.produit_code == element.produit_code) {
                vm.chargement["ch"]["produits"][index]["quantite"] = ligne.quantite;

                vm.chargement["ch"]["produits"][index]["prix_achat_ht"] = ligne.prix_achat_ht;
                vm.chargement["ch"]["produits"][index]["prix_achat_ttc"] = ligne.prix_achat_ttc;
                vm.chargement["ch"]["produits"][index]["prix_ht"] = ligne.prix_ht;
                vm.chargement["ch"]["produits"][index]["prix_ttc"] = ligne.prix_ttc;

                vm.chargement["ch"]["produits"][index]["prix_achat_net_ht"] = ligne.prix_achat_net_ht;
                vm.chargement["ch"]["produits"][index]["prix_achat_net_ttc"] = ligne.prix_achat_net_ttc;

                vm.chargement["ch"]["produits"][index]["total_net_ht"] = ligne.total_net_ht;
                vm.chargement["ch"]["produits"][index]["total_net_ttc"] = ligne.total_net_ttc;

                vm.chargement["ch"]["produits"][index]["remise"] = ligne.remise;
                vm.chargement["ch"]["produits"][index]["remise_promo"] = ligne.remise_promo;
                vm.chargement["ch"]["produits"][index]["remise_dc"] = ligne.remise_dc;
                vm.chargement["ch"]["produits"][index]["remise_1"] = ligne.remise_1;
                vm.chargement["ch"]["produits"][index]["remise_2"] = ligne.remise_2;
                vm.chargement["ch"]["produits"][index]["remise_3"] = ligne.remise_3;

                vm.chargement["ch"]["produits"][index]["tva"] = ligne.tva;

                vm.chargement["ch"]["produits"][index]["dlc"] = ligne.dlc;
                vm.chargement["ch"]["produits"][index]["num_lot"] = ligne.num_lot;

                vm.chargement["ch"]["produits"][index]["sous_total_ht"] = ligne.quantite * ligne.prix_achat_ht;

                //vm.changeValue(id)
              }
            });
          });
          vm.calculeTotal();

        }
        $scope.$apply();
        // $('.table').DataTable({
        //     "language": DatatableLang,
        //     "paging": false,
        //     'searching' : false
        // });
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getGammes = function () {
    WS.get("gammes")
      .then(function (response) {
        vm.gammes = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getFamilles = function () {
    WS.get("familles")
      .then(function (response) {
        vm.familles = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getSousfamilles = function () {
    WS.get("sousfamilles")
      .then(function (response) {
        vm.sousfamilles = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.storeLigne_achat = function (chargement) {
    vm.btn_save = true;
    vm.show_loader = true;
    var data = {};
    data.produits = { produits: [] };

    _.each(chargement.ch.produits, (value, key) => {
      if (value.quantite != undefined && value.quantite != null) {
        data.produits.produits.push(value);
      }
    });


    data.entete_achat_code = chargement.ch.entete_achat_code;
    data.colisage = chargement.ch.colisage;
    data.type = chargement.ch.type;

    data.id = vm.chargement["ch"]["id"];
    data.code = vm.chargement["ch"]["code"];
    data.fournisseur_code = vm.chargement["ch"]["fournisseur_code"];
    data.frs_nom = vm.chargement["ch"]["frs_nom"];
    data.frs_mobile = vm.chargement["ch"]["frs_mobile"];
    data.frs_email = vm.chargement["ch"]["frs_email"];
    data.societe_code = vm.chargement["ch"]["societe_code"];
    data.soussociete_code = vm.chargement["ch"]["soussociete_code"];
    data.type = vm.chargement["ch"]["type"];
    data.document_type = vm.chargement["ch"]["document_type"];
    data.total_ht_ligne = vm.chargement["ch"]["total_ht_ligne"];
    data.remise_facture = vm.chargement["ch"]["remise_facture"];
    data.remise_espece = vm.chargement["ch"]["remise_espece"];
    data.remise_pied = vm.chargement["ch"]["remise_pied"];
    data.total_tva = vm.chargement["ch"]["total_tva"];
    data.timbre = vm.chargement["ch"]["timbre"];
    data.montant_rs = vm.chargement["ch"]["montant_rs"];
    data.retenue_1 = vm.chargement["ch"]["retenue_1"];
    data.retenue_2 = vm.chargement["ch"]["retenue_2"];
    data.retenue_3 = vm.chargement["ch"]["retenue_3"];

    data.sous_total_ht = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] == 'avoir_financier') ? vm.chargement["ch"]["sous_total_ht"] * -1 : vm.chargement["ch"]["net_a_payer"];
    data.total_ht = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] == 'avoir_financier') ? vm.chargement["ch"]["total_ht"] * -1 : vm.chargement["ch"]["net_a_payer"];
    data.montant_total_ttc = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] == 'avoir_financier') ? vm.chargement["ch"]["montant_total_ttc"] * -1 : vm.chargement["ch"]["net_a_payer"];
    data.net_a_payer = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] == 'avoir_financier') ? vm.chargement["ch"]["net_a_payer"] * -1 : vm.chargement["ch"]["net_a_payer"];


    data.commentaire = vm.chargement["ch"]["commentaire"];
    data.anomalie = vm.chargement["ch"]["anomalie"];
    data.transporteur_code = vm.chargement["ch"]["transporteur_code"];
    data.transporteur_libelle = vm.chargement["ch"]["transporteur_libelle"];
    data.chauffeur = vm.chargement["ch"]["chauffeur"];
    data.matricule = vm.chargement["ch"]["matricule"];
    data.user_code = vm.chargement["ch"]["user_code"];
    data.code_commande = vm.chargement["ch"]["code_commande"];
    data.document_code = vm.chargement["ch"]["document_code"];
    data.date_document = vm.chargement["ch"]["date_document"];
    data.remise = vm.chargement["ch"]["remise"];
    data.tva = vm.chargement["ch"]["tva"];
    data.cloture = vm.chargement["ch"]["cloture"];

    console.log(data);

    var nbr_error = 0;
    vm.message_error = ''

    // if(data.produits.produits.length == 0){
    //   nbr_error = nbr_error + 1
    //   vm.message_error = 'Veuillez selectionner au moins un produit'
    // }

    if (data.fournisseur_code == "" || data.fournisseur_code == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le fournisseur'
    }
    if (data.soussociete_code == "" || data.soussociete_code == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le disitributeur'
    }


    if (data.document_type == "" || data.document_type == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le Document'
    }
    if (data.type == "" || data.type == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le type'
    }
    if (data.date_document == "" || data.date_document == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner la date'
    }
    if (data.code == "" || data.code == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le code de facture'
    }


    if (nbr_error == 0) {
      WS.post("entete_achat/storeLigne", data)
        .then(function (response) {
          if (response.data.success) {
            swal(
              $translate.instant("Succès"),
              $translate.instant("chargement ajoutée avec Succès"),
              "success"
            )
              .then(function () {
                vm.btn_save = false;
                vm.show_loader = false;

                $state.go('app.achat.details', { code: response.data.code }, {
                  reload: false,
                  inherit: false
                });
                vm.show_loader = false;

                //$window.location.reload();
              })
              .catch(function (error) {
                vm.btn_save = false;
                vm.show_loader = false;
                $state.go('app.achat.details', { code: response.data.code }, {
                  reload: false,
                  inherit: false
                });
                vm.show_loader = false;
                $scope.$apply();
                //$window.location.reload();
              });
          }
          vm.btn_save = false;
          vm.show_loader = false;
          $scope.$apply();
        })
        .then(null, function (error) {
          vm.btn_save = false;
          vm.show_loader = false;
          $scope.$apply();
          console.log(error);
        });
    } else {
      vm.btn_save = false;
      vm.show_loader = false;
      swal(
        $translate.instant(`Oups`),
        $translate.instant(vm.message_error),
        "warning"
      )
    }

  };


  vm.storeLigne_achatOneProds = function (chargement) {
    vm.btn_save = true;
    vm.show_loader = true;
    var data = {};
    data.produits = { produits: [] };

    _.each(chargement.ch.produits, (value, key) => {
      if (value.quantite != undefined && value.quantite != null) {
        data.produits.produits.push(value);
      }
    });


    data.entete_achat_code = chargement.ch.entete_achat_code;
    data.colisage = chargement.ch.colisage;
    data.type = chargement.ch.type;

    data.id = vm.chargement["ch"]["id"];
    data.code = vm.chargement["ch"]["code"];
    data.fournisseur_code = vm.chargement["ch"]["fournisseur_code"];
    data.frs_nom = vm.chargement["ch"]["frs_nom"];
    data.frs_mobile = vm.chargement["ch"]["frs_mobile"];
    data.frs_email = vm.chargement["ch"]["frs_email"];
    data.societe_code = vm.chargement["ch"]["societe_code"];
    data.soussociete_code = vm.chargement["ch"]["soussociete_code"];
    data.type = vm.chargement["ch"]["type"];
    data.document_type = vm.chargement["ch"]["document_type"];
    data.total_ht_ligne = vm.chargement["ch"]["total_ht_ligne"];
    data.remise_facture = vm.chargement["ch"]["remise_facture"];
    data.remise_espece = vm.chargement["ch"]["remise_espece"];
    data.remise_pied = vm.chargement["ch"]["remise_pied"];
    data.total_tva = vm.chargement["ch"]["total_tva"];
    data.timbre = vm.chargement["ch"]["timbre"];
    data.montant_rs = vm.chargement["ch"]["montant_rs"];
    data.retenue_1 = vm.chargement["ch"]["retenue_1"];
    data.retenue_2 = vm.chargement["ch"]["retenue_2"];
    data.retenue_3 = vm.chargement["ch"]["retenue_3"];

    data.sous_total_ht = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] == 'avoir_financier') ? vm.chargement["ch"]["sous_total_ht"] * -1 : vm.chargement["ch"]["net_a_payer"];
    data.total_ht = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] == 'avoir_financier') ? vm.chargement["ch"]["total_ht"] * -1 : vm.chargement["ch"]["net_a_payer"];
    data.montant_total_ttc = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] == 'avoir_financier') ? vm.chargement["ch"]["montant_total_ttc"] * -1 : vm.chargement["ch"]["net_a_payer"];
    data.net_a_payer = (vm.chargement["ch"]["type"] == 'avoir' || vm.chargement["ch"]["type"] == 'avoir_financier') ? vm.chargement["ch"]["net_a_payer"] * -1 : vm.chargement["ch"]["net_a_payer"];


    data.commentaire = vm.chargement["ch"]["commentaire"];
    data.anomalie = vm.chargement["ch"]["anomalie"];
    data.transporteur_code = vm.chargement["ch"]["transporteur_code"];
    data.transporteur_libelle = vm.chargement["ch"]["transporteur_libelle"];
    data.chauffeur = vm.chargement["ch"]["chauffeur"];
    data.matricule = vm.chargement["ch"]["matricule"];
    data.user_code = vm.chargement["ch"]["user_code"];
    data.code_commande = vm.chargement["ch"]["code_commande"];
    data.document_code = vm.chargement["ch"]["document_code"];
    data.date_document = vm.chargement["ch"]["date_document"];
    data.remise = vm.chargement["ch"]["remise"];
    data.tva = vm.chargement["ch"]["tva"];
    data.cloture = vm.chargement["ch"]["cloture"];

    var nbr_error = 0;
    vm.message_error = ''

    // if(data.produits.produits.length == 0){
    //   nbr_error = nbr_error + 1
    //   vm.message_error = 'Veuillez selectionner au moins un produit'
    // }

    if (data.fournisseur_code == "" || data.fournisseur_code == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le fournisseur'
    }
    if (data.soussociete_code == "" || data.soussociete_code == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le disitributeur'
    }


    if (data.document_type == "" || data.document_type == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le Document'
    }
    if (data.type == "" || data.type == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le type'
    }
    if (data.date_document == "" || data.date_document == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner la date'
    }
    if (data.code == "" || data.code == undefined) {
      nbr_error = nbr_error + 1
      vm.message_error = 'Veuillez bien selectionner le code de facture'
    }


    if (nbr_error == 0) {
      WS.post("entete_achat/storeLigne", data)
        .then(function (response) {
          if (response.data.success) {
            swal(
              $translate.instant("Succès"),
              $translate.instant("chargement ajoutée avec Succès"),
              "success"
            )
              .then(function () {
                vm.btn_save = false;
                vm.show_loader = false;

                $state.go('app.achat.addNewAchat', { code: response.data.code }, {
                  reload: false,
                  inherit: false
                });
                vm.show_loader = false;

                //$window.location.reload();
              })
              .catch(function (error) {
                vm.btn_save = false;
                vm.show_loader = false;
                $state.go('app.achat.addNewAchat', { code: response.data.code }, {
                  reload: false,
                  inherit: false
                });
                vm.show_loader = false;
                $scope.$apply();
                //$window.location.reload();
              });
          }
          vm.btn_save = false;
          vm.show_loader = false;
          $scope.$apply();
        })
        .then(null, function (error) {
          vm.btn_save = false;
          vm.show_loader = false;
          $scope.$apply();
          console.log(error);
        });
    } else {
      vm.btn_save = false;
      vm.show_loader = false;
      swal(
        $translate.instant(`Oups`),
        $translate.instant(vm.message_error),
        "warning"
      )
    }

  };

  vm.convertirEnBox = function (chargement) {
    if (vm.c_box == true) {
      _.each(chargement.ch.produits, (value, key) => {
        if (value instanceof Object) {
          vm.chargement["ch"]["produits"][key]["quantite"] =
            Number(vm.chargement["ch"]["produits"][key]["quantite"]) /
            Number(value.colisage);
        }
      });
      // cocher colisage
    } else {
      _.each(chargement.ch.produits, (value, key) => {
        if (value instanceof Object) {
          vm.chargement["ch"]["produits"][key]["quantite"] =
            Number(vm.chargement["ch"]["produits"][key]["quantite"]) *
            Number(value.colisage);
        }
      });
      // decocher colisage
    }
    if (vm.c_box == false) {
      vm.c_box_title = "En Box";
    } else {
      vm.c_box_title = "En piéce";
    }
    vm.chargement["ch"]["colisage"] = vm.c_box;
    vm.c_box = !vm.c_box;
  };

  vm.findEnteteByCode = function () {
    if ($state.params.code) {
      vm.update_code_achat = true

      WS.getData("entete_achat/findEnteteByCode", { code: $state.params.code })
        .then(function (response) {
          vm.entete_achat = response.data;
          console.log(vm.entete_achat);
          if (vm.entete_achat) {
            vm.chargement["ch"]["id"] = vm.entete_achat.id;
            vm.chargement["ch"]["code"] = vm.entete_achat.code;
            vm.chargement["ch"]["fournisseur_code"] =
              vm.entete_achat.fournisseur_code;
            vm.chargement["ch"]["frs_nom"] = vm.entete_achat.frs_nom;
            vm.chargement["ch"]["frs_mobile"] = vm.entete_achat.frs_mobile;
            vm.chargement["ch"]["frs_email"] = vm.entete_achat.frs_email;
            vm.chargement["ch"]["societe_code"] = vm.entete_achat.societe_code;
            vm.chargement["ch"]["soussociete_code"] =
              vm.entete_achat.soussociete_code;
            vm.chargement["ch"]["type"] = vm.entete_achat.type;
            vm.chargement["ch"]["document_type"] = vm.entete_achat.document_type;
            vm.chargement["ch"]["total_ht_ligne"] =
              vm.entete_achat.total_ht_ligne;
            vm.chargement["ch"]["sous_total_ht"] = vm.entete_achat.sous_total_ht;
            vm.chargement["ch"]["remise_facture"] =
              vm.entete_achat.remise_facture;
            vm.chargement["ch"]["remise_espece"] = vm.entete_achat.remise_espece;
            vm.chargement["ch"]["remise_pied"] = vm.entete_achat.remise_pied;
            vm.chargement["ch"]["total_ht"] = vm.entete_achat.total_ht;
            vm.chargement["ch"]["total_tva"] = vm.entete_achat.total_tva;
            vm.chargement["ch"]["montant_total_ttc"] =
              vm.entete_achat.montant_total_ttc;
            vm.chargement["ch"]["timbre"] = vm.entete_achat.timbre;
            vm.chargement["ch"]["montant_rs"] = vm.entete_achat.montant_rs;
            vm.chargement["ch"]["retenue_1"] = vm.entete_achat.retenue_1;
            vm.chargement["ch"]["retenue_2"] = vm.entete_achat.retenue_2;
            vm.chargement["ch"]["retenue_3"] = vm.entete_achat.retenue_3;
            vm.chargement["ch"]["net_a_payer"] = vm.entete_achat.net_a_payer;
            vm.chargement["ch"]["commentaire"] = vm.entete_achat.commentaire;
            vm.chargement["ch"]["anomalie"] = vm.entete_achat.anomalie;
            vm.chargement["ch"]["transporteur_code"] =
              vm.entete_achat.transporteur_code;
            vm.chargement["ch"]["transporteur_libelle"] =
              vm.entete_achat.transporteur_libelle;
            vm.chargement["ch"]["chauffeur"] = vm.entete_achat.chauffeur;
            vm.chargement["ch"]["matricule"] = vm.entete_achat.matricule;
            vm.chargement["ch"]["user_code"] = vm.entete_achat.user_code;
            vm.chargement["ch"]["date"] = vm.entete_achat.date;
            vm.chargement["ch"]["code_commande"] = vm.entete_achat.code_commande;
            vm.chargement["ch"]["document_code"] = vm.entete_achat.document_code;
            vm.chargement["ch"]["date_document"] = vm.entete_achat.date_document;
            vm.chargement["ch"]["remise"] = vm.entete_achat.remise;
            vm.chargement["ch"]["tva"] = vm.entete_achat.tva;
            vm.chargement["ch"]["cloture"] = vm.entete_achat.cloture;
          } else {
            //   vm.entete_achat.cloture = 0;

            //   vm.chargement["ch"]["id"] = null;
            //   vm.chargement["ch"]["code"] = "";
            //   vm.chargement["ch"]["fournisseur_code"] = "";
            //   vm.chargement["ch"]["frs_nom"] = "";
            //   vm.chargement["ch"]["frs_mobile"] = "";
            //   vm.chargement["ch"]["frs_email"] = "";
            //   vm.chargement["ch"]["societe_code"] = "";
            //   vm.chargement["ch"]["soussociete_code"] = "";
            //   vm.chargement["ch"]["type"] = "";
            //   vm.chargement["ch"]["document_type"] = "";
            //   vm.chargement["ch"]["total_ht_ligne"] = null;
            //   vm.chargement["ch"]["sous_total_ht"] = null;
            //   vm.chargement["ch"]["remise_facture"] = null;
            //   vm.chargement["ch"]["remise_espece"] = null;
            //   vm.chargement["ch"]["remise_pied"] = null;
            //   vm.chargement["ch"]["total_ht"] = null;
            //   vm.chargement["ch"]["total_tva"] = null;
            //   vm.chargement["ch"]["montant_total_ttc"] = null;
            //   vm.chargement["ch"]["timbre"] = null;
            //   vm.chargement["ch"]["montant_rs"] = null;
            //   vm.chargement["ch"]["retenue_1"] = null;
            //   vm.chargement["ch"]["retenue_2"] = null;
            //   vm.chargement["ch"]["retenue_3"] = null;
            //   vm.chargement["ch"]["net_a_payer"] = null;
            //   vm.chargement["ch"]["commentaire"] = "";
            //   vm.chargement["ch"]["anomalie"] = "";
            //   vm.chargement["ch"]["transporteur_code"] = "";
            //   vm.chargement["ch"]["transporteur_libelle"] = "";
            //   vm.chargement["ch"]["chauffeur"] = "";
            //   vm.chargement["ch"]["matricule"] = "";
            //   vm.chargement["ch"]["user_code"] = "";
            //   vm.chargement["ch"]["date"] = "";
            //   vm.chargement["ch"]["code_commande"] = "";
            //   vm.chargement["ch"]["document_code"] = "";
            //   vm.chargement["ch"]["date_document"] = "";
            //   vm.chargement["ch"]["remise"] = null;
            //   vm.chargement["ch"]["tva"] = null;
            //   vm.chargement["ch"]["cloture"] = null;
          }
          console.log(
            "----------------------test cloture ------------------------"
          );
          console.log(vm.entete_achat.cloture, vm.entete_achat);
          $scope.$apply();
        })
        .then(null, function (error) {
          console.log(error);
        });
    } else {
      vm.update_code_achat = false
    }
  };

  vm.clotureEntete_achat = function (code) {
    swal({
      title: $translate.instant("Cloture"),
      text: $translate.instant("Valider l'achat de") + " : " + code,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: $translate.instant("Oui"),
      cancelButtonText: $translate.instant("Non"),
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: true,
    })
      .then(function () {
        WS.put("entete_achat/clotureEntete_achat/" + code, { cloture: 1 })
          .then(function (response) {
            if (response.data.success == true) {
              swal(
                $translate.instant(`Succé`),
                $translate.instant(`Achat Ajouté avec succé`),
                "success"
              ).then(function () {
                $window.location.reload();
              });
            }
          })
          .then(null, function (error) {
            console.log(error);
          });
      })
      .catch(function () {
        console.log("cancelled!");
      });
  };

  vm.getStockDepotByType = function (type, soussociete_code) {
    WS.getData("stock_depot/getStockDepotByType", { soussociete_code, type })
      .then(function (response) {
        _.each(vm.chargement.ch.produits, (value, key) => {
          if (value instanceof Object) {
            _.each(response.data, (s_value, s_key) => {
              if (s_value.produit_code == value.produit_code) {
                vm.chargement["ch"]["produits"][key]["quantite"] = Number(
                  s_value[type]
                );
              }
            });
          }
        });
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.exportAchat = () => {
    WS.getData("entete_achat/exportEnteteAchat", { code: $state.params.code })
      .then(function (response) {
        if (response.data.length > 0) {
          var filename =
            response.data[0].type +
            "-" +
            response.data[0].code +
            "-" +
            response.data[0].date;
          var file = {
            sheetid: filename,
            headers: true,
            caption: {
              title: filename,
              style: "font-size: 50px; color:blue;",
            },
            column: {
              style: "font-size:20px;background:#d1d1d1;",
            },
            columns: [
              { columnid: "code", title: "Code" },
              { columnid: "date", title: "Date" },
              { columnid: "ru", title: "Retour Usine" },
              { columnid: "type", title: "Type" },
              { columnid: "cloture", title: "Cloture" },
              { columnid: "user", title: "Commercial" },
              { columnid: "soussociete", title: "Distributeur" },
              { columnid: "fournisseur", title: "Fournisseur" },
              { columnid: "produit_code", title: "Code produit" },
              { columnid: "produit", title: "Produit" },
              { columnid: "quantite", title: "Quantite" },
              { columnid: "prix_achat_ht", title: "prix achat ht" },
              { columnid: "prix_achat_ttc", title: "prix achat ttc" },
              { columnid: "prix_ttc", title: "prix ttc" },
              { columnid: "gamme", title: "Gamme" },
              { columnid: "marque", title: "Marque" },
              { columnid: "famille", title: "Famille" },
              { columnid: "sousfamille", title: "Soufamille" },
            ],
            row: {
              style: function (sheet, row, rowidx) {
                return (
                  "text-align:center;background:" +
                  (rowidx % 2 ? "#fff" : "#f1f1f1") +
                  ";height:100px!important;"
                );
              },
            },
          };
          alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [
            file,
            response.data,
          ]);
        }

        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectSoussocietesOnly = function () {
    WS.get("soussocietes/selectSoussocietes").then(
      function (response) {
        vm.soussocietes = response.data;
        if (vm.soussocietes.length == 1) {
          vm.chargement.ch.soussociete_code = vm.soussocietes[0].code;
        }

        $scope.$apply();
      },
      function (error) {
        console.log(error);
      }
    );
  };

  vm.addPaiement = () => {
    const data = {};
    // vm.paiement.code = vm.document.code;
    vm.paiement.fournisseur_code = vm.list_factures[0].fournisseur_code;
    vm.paiement.soussociete_code = vm.list_factures[0].soussociete_code;
    vm.paiement.paiement_code = vm.searchbox.paiement_code;
    // vm.paiement.montant_rs = vm.document.montant_rs;
    vm.list_rs = [];
    _.each(vm.retenue_rs, (element, index) => {
      vm.list_rs.push({
        code: index,
        selected: element.selected,
        montant_rs: element.montant_rs
      });
    });
    vm.paiement.montant_rs = vm.list_rs;
    vm.paiement.entetes = vm.list_factures;
    console.log(vm.paiement)
    vm.error_msg = '';
    vm.nb_error = 0;
    _.each(vm.paiement.cheques, (element, index) => {
      if (element.cheque && element.cheque != '' && (!element.banque_cheque || !element.num_cheque || !element.date_echeance_cheque)) {
        vm.nb_error += 1;
        var ligne = index + 1
        vm.error_msg = 'Veuillez bien remplir les information du chéque N° : ' + ligne;
      }
    });

    _.each(vm.paiement.traites, (element, index) => {
      if (element.traite && (!element.banque_traite || !element.num_traite || !element.date_echeance_traite)) {
        vm.nb_error += 1;
        var ligne = index + 1
        vm.error_msg = 'Veuillez bien remplir les information de la traite N° : ' + ligne;
      }
    });

    if (vm.nb_error > 0) {
      swal(
        $translate.instant("Oups!"),
        $translate.instant(vm.error_msg),
        "warning"
      )
    } else {

      vm.montant_espece = (vm.paiement.espece) ? Number(vm.paiement.espece) : 0;
      vm.montant_cheque = _.reduce(vm.paiement.cheques, function (memo, num) { return memo + Number(num.cheque); }, 0);
      vm.montant_cheque = vm.montant_cheque ? vm.montant_cheque : 0;
      vm.montant_traite = _.reduce(vm.paiement.traites, function (memo, num) { return memo + Number(num.traite); }, 0);
      vm.montant_traite = vm.montant_traite ? vm.montant_traite : 0;
      vm.montant_total = Number(vm.montant_espece) + Number(vm.montant_cheque) + Number(vm.montant_traite);


      console.log(Number(vm.montant_espece), vm.montant_cheque, vm.montant_traite, vm.montant_total)
      if (vm.montant_total <= 0) {
        swal(
          $translate.instant("Oups!"),
          $translate.instant('Veuillez bien remplir le montant de paiement!'),
          "warning"
        )
      } else {
        WS.post("entete_achat/addPaiement", vm.paiement)
          .then(function (response) {
            if (response.data.success && response.data.errors.length == 0) {
              swal(
                $translate.instant("Succès"),
                $translate.instant("paiement ajoutée avec Succès"),
                "success"
              )
                .then(function () {
                  $window.location.reload();
                })
                .catch(function (error) {
                  $window.location.reload();
                });
            }
            if (response.data.success && Object.keys(response.data.errors).length > 0) {
              vm.errors = response.data.errors;
              console.log(vm.errors);
            }
            console.log(vm.errors);

            vm.btn_save = true;
            $scope.$apply();
          })
          .then(null, function (error) {
            console.log(error);
          });
      }

    }

  };

  vm.getPaiementByCode = (entete_achat_code) => {
    WS.getData("entete_achat/getPaiementByCode", { entete_achat_code })
      .then(function (response) {
        if (response.status == 200) {
          vm.sum_paiement =
            Number(response.data.espece) +
            Number(response.data.cheque) +
            Number(response.data.traite) +
            Number(response.data.retenue_source);
          $scope.$apply();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.addCheque = (index) => {
    // <div class="form-group">
    //                             <input type="text" class=""
    //                                 ng-model="vm.paiement.cheques[${index}].banque_cheque"

    //                                 placeholder="${$translate.instant('Banque Cheque')}">
    //                         </div>
    const newBlock = $compile(`
                      <div id="block_cheque_${index}">
                      <div class="flex_paiement">
                        <div class="">
                            <div class="form-group">
                                <input type="text" class=""  style="width: 95%;"
                                ng-model="vm.paiement.cheques[${index}].cheque"
                                placeholder="${$translate.instant('Montant Cheque')}">
                            </div>
                        </div>
                        <div class="">
                            
                            <div class="form-group">
                                <select ng-model="vm.paiement.cheques[${index}].banque_id" ng-change="vm.selectedBanque(${index}, vm.paiement.cheques[${index}].banque_id, 'cheque');"
                                    ng-init="vm.paiement.cheques[${index}].banque_id=''"
                                    style="width: 95%;margin-top: -3px;">
                                    <option value=""> {{'Choisir'| translate }}</option>
                                    <option ng-repeat="(k,banque) in vm.select_banques" value="{{ banque.id}}">
                                        {{banque.agence}} {{banque.banque}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="">
                            <div class="form_group">
                                <input type="text" class=""  style="width: 95%;"
                                    ng-model="vm.paiement.cheques[${index}].num_cheque"
                                    placeholder="${$translate.instant('Num Cheque')}">
                            </div>
                        </div>
                        <div class="">
                            <div class="form-group">
                                <datepicker date-format="yyyy-MM-dd"
                                    selector="form-control-date">
                                    <div class="input-group">
                                        <input class="form-control-date"  style="width: 95%;"
                                            ng-model="vm.paiement.cheques[${index}].date_echeance_cheque"
                                            placeholder="${$translate.instant('Date Echeance Cheque')}" />
                                        <span class="datepick_paiement"
                                            style="cursor: pointer"
                                            ng-click="vm.paiement.cheques[${index}].date_echeance_cheque=''">
                                            <i class="fa fa-lg fa-calendar"></i>
                                        </span>
                                    </div>
                                </datepicker>
                            </div>
                        </div>
                        <div class="text-center">
                            <i class="fa fa-minus" 
                              style="color: red;
                              font-size: 22px;
                              cursor: pointer;
                              text-align: center;
                              margin-top: 7px;"
                              ng-click="vm.removeCheque(${index})"></i>
                        </div>
                      </div>
                    <div class="clearfix"></div>
                    </div>
              `)($scope);

    $('.panel_cheques').append(newBlock);
    vm.countBlockCheques = Number(index) + 1
  }


  vm.addTraite = (index) => {


    // <div class="form-group">
    //     <input type="text" class=""
    //         ng-model="vm.paiement.traites[${index}].banque_traite"

    //         placeholder="${$translate.instant('Banque Traite')}">
    // </div>
    const newBlock = $compile(`
                    <div id="block_traite_${index}">
                    <div class="flex_paiement">
                      <div class="">
                          <div class="form-group">
                              <input type="text" class=""  style="width: 95%;"
                              ng-model="vm.paiement.traites[${index}].traite"
                              placeholder="${$translate.instant('Montant Traite')}">
                          </div>
                      </div>
                      <div class="">
                          <div class="form-group">
                              <select ng-model="vm.paiement.traites[${index}].banque_id" 
                                      ng-change="vm.selectedBanque(${index}, vm.paiement.traites[${index}].banque_id, 'traite');"
                                      style="width: 95%;margin-top: -3px;">
                                  <option ng-repeat="(k,banque) in vm.select_banques" value="{{ banque.id}}">
                                      {{banque.agence}} {{banque.banque}}
                                  </option>
                              </select>
                          </div>
                      </div>
                      <div class="">
                          <div class="form_group">
                              <input type="text" class=""  style="width: 95%;"
                                  ng-model="vm.paiement.traites[${index}].num_traite"
                                  placeholder="${$translate.instant('Num Traite')}">
                          </div>
                      </div>
                      <div class="">
                          <div class="form-group">
                              <datepicker date-format="yyyy-MM-dd"
                                  selector="form-control-date">
                                  <div class="input-group">
                                      <input class="form-control-date"  style="width: 95%;"
                                          ng-model="vm.paiement.traites[${index}].date_echeance_traite"
                                          placeholder="${$translate.instant('Date Echeance traite')}" />
                                      <span class="datepick_paiement"
                                          style="cursor: pointer"
                                          ng-click="vm.paiement.traites[${index}].date_echeance_traite=''">
                                          <i class="fa fa-lg fa-calendar"></i>
                                      </span>
                                  </div>
                              </datepicker>
                          </div>
                      </div>
                      <div class="text-center">
                          <i class="fa fa-minus" 
                            style="color: red;
                            font-size: 22px;
                            cursor: pointer;
                            text-align: center;
                            margin-top: 7px;"
                            ng-click="vm.removeTraite(${index})"></i>
                      </div>
                    </div>
                  <div class="clearfix"></div>
                  </div>
            `)($scope);

    $('.panel_traites').append(newBlock);
    vm.countBlockTraites = Number(index) + 1
    console.log(index, vm.countBlockTraites, vm.paiement.traites)
  }

  vm.removeCheque = function (index) {
    if (vm.paiement.cheques != undefined && vm.paiement.cheques[index]) {
      var mod = _.omit($parse("vm.paiement.cheques")($scope), index);
      $parse("vm.paiement.cheques").assign($scope, mod);
    }
    $('#block_cheque_' + index).remove();
    //vm.recalculateSolde();
  };

  vm.removeTraite = function (index) {
    if (vm.paiement.traites != undefined && vm.paiement.traites[index]) {
      var mod = _.omit($parse("vm.paiement.traites")($scope), index);
      $parse("vm.paiement.traites").assign($scope, mod);
    }
    $('#block_traite_' + index).remove();
    //vm.recalculateSolde();
    console.log(vm.paiement.traites)
  };


  vm.recalculateSolde = () => {

    vm.solde_totale = 0;
    vm.solde_totale += vm.paiement.espece ? Number(vm.paiement.espece) : 0;

    angular.forEach(vm.paiement.cheques, function (value, key) {
      vm.solde_totale += value.cheque ? Number(value.cheque) : 0;
    });
    angular.forEach(vm.paiement.traites, function (value, key) {
      vm.solde_totale += value.traite ? Number(value.traite) : 0;
    });
    console.log('vm.solde_totale', vm.solde_totale)
  }

  $scope.$watch("vm.paiement.espece", function (value) { vm.recalculateSolde(); });
  $scope.$watch('vm.paiement.cheques', function (newVal, oldVal) { vm.recalculateSolde(); }, true);
  $scope.$watch('vm.paiement.traites', function (newVal, oldVal) { vm.recalculateSolde(); }, true);




  // sudo npm install angular-async-await --save
  vm.printElement = function (html, entete) {


    WS.getData("entete_achat/getByCode", { code: entete.code })
      .then(function (response) {
        if (response.status == 200) {
          vm.document_print = response.data;
          $scope.$apply();

          var mywindow = $window.open('', 'PRINT');

          mywindow.document.write(`<html>
                                      <head>
                                      <title> ${document.title} </title>
                                      <style>
                                          .noprint{display: none;}.text-center{text-align:center}
                                      </style>
                                  `);
          mywindow.document.write('</head><body >');

          mywindow.document.write(document.getElementById(html).innerHTML);

          mywindow.document.write('</body></html>');
          mywindow.document.close(); // necessary for IE >= 10
          mywindow.focus(); // necessary for IE >= 10*/

          mywindow.print();
          mywindow.close();

          return true
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });

    //   console.log(vm.document);
    //    $scope.$apply();



  }

  // $scope.$watch("vm.document_print", function (value) {
  //   console.log(vm.document_print);
  //   vm.printElement('print_retenue');
  // });



  vm.selected_quantite = '';


  vm.getStatAchat = () => {
    WS.getData("entete_achat/getStatAchat", vm.filter_stat)
      .then(function (response) {
        vm.stat_prods = response.data.produits;
        vm.factures = response.data.factures;
        vm.mvt_fournisseurs = response.data.mvt_fournisseurs;

        vm.quantites = 0;
        vm.total_net_ht = 0;

        _.each(vm.stat_prods, element => {
          vm.quantites += Number(element.quantite);
          vm.total_net_ht += Number(element.total_net_ht);
        });
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  }




  /**
   * STATS amcharts 5
   */


  vm.pieFamilleSousfamilles = () => {

    $('.pie_am5_01').empty();
    $('.pie_am5_01').append(`<div id="pie_achat_famille_sousfamille" style="width: 100%;height: 500px;"></div>`);
    WS.getData("entete_achat/pieFamilleSousfamilles", vm.filter_stat)
      .then(function (response) {
        vm.lists_prods = response.data;
        vm.pie_familles = [];
        vm.pie_gammes = [];
        vm.sousfamille_codes = [];



        if (vm.lists_prods.length > 0) {
          vm.drawPie1();
          vm.drawPie2();
        }
        $scope.$apply();



      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.drawPie1 = () => {
    _.each(vm.lists_prods, element => {

      if (!_.isObject(vm.sousfamille_codes[element.sousfamille_code])) {
        vm.sousfamille_codes[element.sousfamille_code] = {};
      }

      //vm.sousfamille_codes[element.sousfamille_code].category_code = element.sousfamille_code;
      vm.sousfamille_codes[element.sousfamille_code].famille_code = element.famille_code;
      vm.sousfamille_codes[element.sousfamille_code].category = element.sousfamille;
      vm.sousfamille_codes[element.sousfamille_code].value = 0;

      //Famille 
      if (!_.isObject(vm.pie_familles[element.famille_code])) {
        vm.pie_familles[element.famille_code] = {};
      }
      vm.pie_familles[element.famille_code].category_code = element.famille_code;
      vm.pie_familles[element.famille_code].category = element.famille;
      //Quantite
      vm.pie_familles[element.famille_code].value =
        vm.pie_familles[element.famille_code].value ? vm.pie_familles[element.famille_code].value + Number(element.quantite) : Number(element.quantite);

      //Chiffre
      // vm.pie_familles[element.famille_code].chiffre = 
      //     vm.pie_familles[element.famille_code].chiffre ? vm.pie_familles[element.famille_code].chiffre + Number(element.chiffre) : Number(element.chiffre);

      //Sous familles
      if (!_.isObject(vm.pie_familles[element.famille_code].subData)) {
        vm.pie_familles[element.famille_code].subData = {};
      }

      if (!_.isObject(vm.pie_familles[element.famille_code].subData[element.sousfamille_code])) {
        vm.pie_familles[element.famille_code].subData[element.sousfamille_code] = {};
      }

      vm.pie_familles[element.famille_code].subData[element.sousfamille_code].category_code = element.sousfamille_code;
      vm.pie_familles[element.famille_code].subData[element.sousfamille_code].category = element.sousfamille;

      // vm.pie_familles[element.famille_code].subData[element.sousfamille_code].chiffre = 
      //     vm.pie_familles[element.famille_code].subData[element.sousfamille_code].chiffre ? vm.pie_familles[element.famille_code].subData[element.sousfamille_code].chiffre + Number(element.chiffre) : Number(element.chiffre);

      vm.pie_familles[element.famille_code].subData[element.sousfamille_code].value =
        vm.pie_familles[element.famille_code].subData[element.sousfamille_code].value ? vm.pie_familles[element.famille_code].subData[element.sousfamille_code].value + Number(element.quantite) : Number(element.quantite);
    });
    //console.log('vm.pie_familles', vm.pie_familles);
    vm.pie_familles = Object.values(vm.pie_familles);
    _.each(vm.pie_familles, (element, index) => {
      vm.pie_familles[index].subData = Object.values(element.subData);
    })
    //console.log('flat 1', vm.pie_familles);

    vm.sousfamille_codes = Object.values(vm.sousfamille_codes);
    //console.log('flat 2', vm.sousfamille_codes);



    $(document).ready(function () {
      am5.ready(function () {

        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new("pie_achat_famille_sousfamille");

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)]);

        var container = root.container.children.push(
          am5.Container.new(root, {
            width: am5.p100,
            height: am5.p100,
            layout: root.horizontalLayout
          })
        );

        // Create main chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var chart = container.children.push(
          am5percent.PieChart.new(root, {
            tooltip: am5.Tooltip.new(root, {})
          })
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var series = chart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category",
            alignLabels: false
          })
        );

        series.labels.template.setAll({
          textType: "circular",
          radius: 4
        });
        series.ticks.template.set("visible", false);
        series.slices.template.set("toggleKey", "none");

        // add events
        series.slices.template.events.on("click", function (e) {
          console.log(e.target);
          selectSlice(e.target, true);
        });

        // Create sub chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var subChart = container.children.push(
          am5percent.PieChart.new(root, {
            radius: am5.percent(50),
            tooltip: am5.Tooltip.new(root, {})
          })
        );

        // Create sub series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var subSeries = subChart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category"
          })
        );

        subSeries.data.setAll(vm.sousfamille_codes);

        // console.log('subSeries ======>', subSeries)
        subSeries.slices.template.set("toggleKey", "none");
        //console.log('subSeries ======> 2', subSeries)

        var selectedSlice;

        series.on("startAngle", function () {
          updateLines();
        });

        container.events.on("boundschanged", function () {
          root.events.on("frameended", function () {
            updateLines();
          })
        })

        function updateLines() {
          if (selectedSlice) {
            var startAngle = selectedSlice.get("startAngle");
            var arc = selectedSlice.get("arc");
            var radius = selectedSlice.get("radius");

            var x00 = radius * am5.math.cos(startAngle);
            var y00 = radius * am5.math.sin(startAngle);

            var x10 = radius * am5.math.cos(startAngle + arc);
            var y10 = radius * am5.math.sin(startAngle + arc);

            var subRadius = subSeries.slices.getIndex(0).get("radius");
            var x01 = 0;
            var y01 = -subRadius;

            var x11 = 0;
            var y11 = subRadius;

            var point00 = series.toGlobal({ x: x00, y: y00 });
            var point10 = series.toGlobal({ x: x10, y: y10 });

            var point01 = subSeries.toGlobal({ x: x01, y: y01 });
            var point11 = subSeries.toGlobal({ x: x11, y: y11 });

            line0.set("points", [point00, point01]);
            line1.set("points", [point10, point11]);
          }
        }

        // lines
        var line0 = container.children.push(
          am5.Line.new(root, {
            position: "absolute",
            stroke: root.interfaceColors.get("text"),
            strokeDasharray: [2, 2]
          })
        );
        var line1 = container.children.push(
          am5.Line.new(root, {
            position: "absolute",
            stroke: root.interfaceColors.get("text"),
            strokeDasharray: [2, 2]
          })
        );

        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
        series.data.setAll(vm.pie_familles);

        function selectSlice(slice, clickedelement = false) {

          selectedSlice = slice;
          var dataItem = slice.dataItem;
          var dataContext = dataItem.dataContext;

          // if(clickedelement==true){
          //     console.log('clickedelm = true',dataItem.dataContext )
          // }

          //update sousfamilles 
          var datas = vm.sousfamille_codes.filter(function (obj) {
            return dataContext.category_code == obj.famille_code;
          })
          subSeries.data.setAll(datas);
          if (dataContext) {
            var i = 0;
            subSeries.data.each(function (dataObject) {
              //console.log('dataObject =================> ', dataObject);
              if (_.isArray(dataContext.subData)) {
                if (dataContext.subData[i]) {
                  if (dataContext.category_code == dataObject.famille_code) {
                    //console.log('dataContext.subData[i]', dataContext.subData[i])
                    subSeries.data.setIndex(i, dataContext.subData[i]);
                  }

                }

              }

              i++;
              console.log(i)
            });
          }

          var middleAngle = slice.get("startAngle") + slice.get("arc") / 2;
          var firstAngle = series.dataItems[0].get("slice").get("startAngle");

          series.animate({
            key: "startAngle",
            to: firstAngle - middleAngle,
            duration: 1000,
            easing: am5.ease.out(am5.ease.cubic)
          });
          series.animate({
            key: "endAngle",
            to: firstAngle - middleAngle + 360,
            duration: 1000,
            easing: am5.ease.out(am5.ease.cubic)
          });
        }

        container.appear(1000, 10);

        series.events.on("datavalidated", function () {
          selectSlice(series.slices.getIndex(0));
        });
        //exporting data
        var exporting = am5plugins_exporting.Exporting.new(root, {
          dataSource: vm.pie_familles,
          menu: am5plugins_exporting.ExportingMenu.new(root, {})
        });
      }); // end am5.ready()
    })
  }


  vm.drawPie2 = () => {
    $('.pie_am5_02').empty();
    $('.pie_am5_02').append(`<div id="pie_achat_gamme_famille_sousfamille" style="width: 100%;height: 500px;"></div>`);

    _.each(vm.lists_prods, element => {

      //GAMME 
      if (!_.isObject(vm.pie_gammes[element.gamme_code])) {
        vm.pie_gammes[element.gamme_code] = {};
      }
      vm.pie_gammes[element.gamme_code].category_code = element.gamme_code;
      vm.pie_gammes[element.gamme_code].category = element.gamme;
      //Quantite
      // vm.pie_gammes[element.gamme_code].value = 
      //     vm.pie_gammes[element.gamme_code].value ? vm.pie_gammes[element.gamme_code].value + Number(element.quantite) : Number(element.quantite);

      // //Chiffre
      // vm.pie_gammes[element.gamme_code].chiffre = 
      //     vm.pie_gammes[element.gamme_code].chiffre ? vm.pie_gammes[element.gamme_code].chiffre + Number(element.chiffre) : Number(element.chiffre);

      //Famille 
      if (!_.isObject(vm.pie_gammes[element.gamme_code].children)) {
        vm.pie_gammes[element.gamme_code].children = {};
      }
      if (!_.isObject(vm.pie_gammes[element.gamme_code].children[element.famille_code])) {
        vm.pie_gammes[element.gamme_code].children[element.famille_code] = {};
      }
      vm.pie_gammes[element.gamme_code].children[element.famille_code].category_code = element.famille_code;
      vm.pie_gammes[element.gamme_code].children[element.famille_code].category = element.famille;
      //Quantite
      // vm.pie_gammes[element.gamme_code].children[element.famille_code].value = 
      //     vm.pie_gammes[element.gamme_code].children[element.famille_code].value ? vm.pie_gammes[element.gamme_code].children[element.famille_code].value + Number(element.quantite) : Number(element.quantite);

      // //Chiffre
      // vm.pie_gammes[element.gamme_code].children[element.famille_code].chiffre = 
      //     vm.pie_gammes[element.gamme_code].children[element.famille_code].chiffre ? vm.pie_gammes[element.gamme_code].children[element.famille_code].chiffre + Number(element.chiffre) : Number(element.chiffre);

      //Sous familles
      if (!_.isObject(vm.pie_gammes[element.gamme_code].children[element.famille_code])) {
        vm.pie_gammes[element.gamme_code].children[element.famille_code] = {};
      }

      if (!_.isObject(vm.pie_gammes[element.gamme_code].children[element.famille_code].children)) {
        vm.pie_gammes[element.gamme_code].children[element.famille_code].children = {};
      }

      if (!_.isObject(vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code])) {
        vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code] = {};
      }

      vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].category_code = element.sousfamille_code;
      vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].category = element.sousfamille;

      vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].chiffre =
        vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].chiffre ? vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].chiffre + Number(element.chiffre) : Number(element.chiffre);

      vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].value =
        vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].value ? vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].value + Number(element.quantite) : Number(element.quantite);
    });


    vm.pie_gammes = Object.values(vm.pie_gammes);
    _.each(vm.pie_gammes, (element, index) => {
      vm.pie_gammes[index].children = Object.values(element.children);
      _.each(vm.pie_gammes[index].children, (sf, indsf) => {
        vm.pie_gammes[index].children[indsf].children = Object.values(sf.children)
      })
    })
    console.log('vm.group_gammes', vm.pie_gammes)
    $(document).ready(function () {
      am5.ready(function () {

        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new("pie_achat_gamme_famille_sousfamille");


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
          am5themes_Animated.new(root)
        ]);


        // Create wrapper container
        var container = root.container.children.push(am5.Container.new(root, {
          width: am5.percent(100),
          height: am5.percent(100),
          layout: root.verticalLayout
        }));


        // Create series
        // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
        var series = container.children.push(am5hierarchy.Sunburst.new(root, {
          singleBranchOnly: true,
          downDepth: 10,
          initialDepth: 10,
          topDepth: 1,
          innerRadius: am5.percent(30),
          valueField: "chiffre",
          categoryField: "category",
          childDataField: "children"
        }));

        series.data.setAll([{
          name: "root",
          children: vm.pie_gammes
        }]);

        series.selectDataItem(series.dataItems[0]);

        // Make stuff animate on load
        series.appear(1000, 100);
        //exporting data
        var exporting = am5plugins_exporting.Exporting.new(root, {
          dataSource: vm.pie_gammes,
          menu: am5plugins_exporting.ExportingMenu.new(root, {})
        });
      }); // end am5.ready()
    })
  }



  vm.lineChart1CA = () => {
    $('.line_chart_am5_01').empty();
    $('.line_chart_am5_01').append(`<div id="div_lineChart1CA" style="width: 100%;height: 500px;"></div>`);

    WS.getData("entete_achat/lineChart1CA", vm.filter_line)
      .then(function (response) {
        var field = vm.filter_line.field ? vm.filter_line.field : 'chiffre';
        vm.linechart_data_1 = {};
        _.each(response.data, (element, index) => {
          vm.linechart_data_1[index] = {};
          var quantity = 0
          _.each(element, (gamme, index_gamme) => {
            vm.linechart_data_1[index][gamme.gamme] = Number(gamme[field]);
            quantity += Number(gamme[field]);
          })
          vm.linechart_data_1[index].quantity = quantity;

        })
        console.log(vm.linechart_data_1);
        $(document).ready(function () {
          am5.ready(function () {

            // Create root element
            // https://www.amcharts.com/docs/v5/getting-started/#Root_element
            var root = am5.Root.new("div_lineChart1CA");

            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
              am5themes_Animated.new(root)
            ]);

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            var chart = root.container.children.push(
              am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none"
              })
            );

            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);

            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
            xRenderer.labels.template.setAll({ text: "{realName}" });

            var xAxis = chart.xAxes.push(
              am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: "category",
                renderer: xRenderer,

                tooltip: am5.Tooltip.new(root, {
                  labelText: "{realName}"
                })
              })
            );

            var yAxis = chart.yAxes.push(
              am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                renderer: am5xy.AxisRendererY.new(root, {})
              })
            );

            var yAxis2 = chart.yAxes.push(
              am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                syncWithAxis: yAxis,
                renderer: am5xy.AxisRendererY.new(root, { opposite: true })
              })
            );

            // Create series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            var series = chart.series.push(
              am5xy.ColumnSeries.new(root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis2,
                valueYField: "value",
                sequencedInterpolation: true,
                categoryXField: "category",
                tooltip: am5.Tooltip.new(root, {
                  //labelText: "{provider} {realName}: {valueY}"
                  labelText: "{realName}: {valueY}"
                })
              })
            );

            series.columns.template.setAll({
              fillOpacity: 0.9,
              strokeOpacity: 0
            });
            series.columns.template.adapters.add("fill", (fill, target) => {
              return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            series.columns.template.adapters.add("stroke", (stroke, target) => {
              return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            var lineSeries = chart.series.push(
              am5xy.LineSeries.new(root, {
                name: "Series 2",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "quantity",
                sequencedInterpolation: true,
                stroke: chart.get("colors").getIndex(13),
                fill: chart.get("colors").getIndex(13),
                categoryXField: "category",
                tooltip: am5.Tooltip.new(root, {
                  labelText: "{valueY}"
                })
              })
            );

            lineSeries.strokes.template.set("strokeWidth", 2);

            lineSeries.bullets.push(function () {
              return am5.Bullet.new(root, {
                locationY: 1,
                locationX: undefined,
                sprite: am5.Circle.new(root, {
                  radius: 5,
                  fill: lineSeries.get("fill")
                })
              });
            });

            // when data validated, adjust location of data item based on count
            lineSeries.events.on("datavalidated", function () {
              am5.array.each(lineSeries.dataItems, function (dataItem) {
                // if count divides by two, location is 0 (on the grid)
                if (
                  dataItem.dataContext.count / 2 ==
                  Math.round(dataItem.dataContext.count / 2)
                ) {
                  dataItem.set("locationX", 0);
                }
                // otherwise location is 0.5 (middle)
                else {
                  dataItem.set("locationX", 0.5);
                }
              });
            });

            var chartData = [];

            // Set data
            var data = vm.linechart_data_1;
            // var data = {
            //   "Provider 1": {
            //     "item 1": 10,
            //     "item 2": 35,
            //     "item 3": 5,
            //     "item 4": 20,
            //     quantity: 430
            //   },
            //   "Provider 2": {
            //     "item 1": 15,
            //     "item 3": 21,
            //     quantity: 210
            //   },
            //   "Provider 3": {
            //     "item 2": 25,
            //     "item 3": 11,
            //     "item 4": 17,
            //     quantity: 265
            //   },
            //   "Provider 4": {
            //     "item 3": 12,
            //     "item 4": 15,
            //     quantity: 98
            //   }
            // }; 1643122607

            // process data ant prepare it for the chart
            for (var providerName in data) {
              var providerData = data[providerName];

              // add data of one provider to temp array
              var tempArray = [];
              var count = 0;
              // add items
              for (var itemName in providerData) {
                if (itemName != "quantity") {
                  count++;
                  // we generate unique category for each column (providerName + "_" + itemName) and store realName
                  tempArray.push({
                    category: providerName + "_" + itemName,
                    realName: itemName,
                    value: providerData[itemName],
                    provider: providerName
                  });
                }
              }
              // sort temp array
              tempArray.sort(function (a, b) {
                if (a.value > b.value) {
                  return 1;
                } else if (a.value < b.value) {
                  return -1;
                } else {
                  return 0;
                }
              });

              // add quantity and count to middle data item (line series uses it)
              var lineSeriesDataIndex = Math.floor(count / 2);
              tempArray[lineSeriesDataIndex].quantity = providerData.quantity;
              tempArray[lineSeriesDataIndex].count = count;
              // push to the final data
              am5.array.each(tempArray, function (item) {
                chartData.push(item);
              });

              // create range (the additional label at the bottom)

              var range = xAxis.makeDataItem({});
              xAxis.createAxisRange(range);

              range.set("category", tempArray[0].category);
              range.set("endCategory", tempArray[tempArray.length - 1].category);

              var label = range.get("label");

              label.setAll({
                text: tempArray[0].provider,
                dy: 30,
                fontWeight: "bold",
                tooltipText: tempArray[0].provider
              });

              var tick = range.get("tick");
              tick.setAll({ visible: true, strokeOpacity: 1, length: 50, location: 0 });

              var grid = range.get("grid");
              grid.setAll({ strokeOpacity: 1 });
            }

            // add range for the last grid
            var range = xAxis.makeDataItem({});
            xAxis.createAxisRange(range);
            range.set("category", chartData[chartData.length - 1].category);
            var tick = range.get("tick");
            tick.setAll({ visible: true, strokeOpacity: 1, length: 50, location: 1 });

            var grid = range.get("grid");
            grid.setAll({ strokeOpacity: 1, location: 1 });

            xAxis.data.setAll(chartData);
            series.data.setAll(chartData);
            lineSeries.data.setAll(chartData);

            //exporting data
            var exporting = am5plugins_exporting.Exporting.new(root, {
              dataSource: vm.linechart_data_1,
              menu: am5plugins_exporting.ExportingMenu.new(root, {})
            });
            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear(1000);
            chart.appear(1000, 100);

          }); // end am5.ready()
        })
        $scope.$apply();



      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.getEncoursAchat = () => {

    $('.line_chart_am5_02').empty();
    $('.line_chart_am5_02').append(`<div id="div_encours_barline1" style="width: 100%;height: 500px;"></div>`);

    WS.getData("entete_achat/getEncoursAchat", vm.filter_line)
      .then(function (response) {

        console.log(response.data);
        vm.encours_achat = response.data.achat;
        vm.encours_paiement = response.data.paiement;
        vm.encours_res = [];
        _.each(vm.encours_achat, (element, index_achat) => {
          var object = element[0];
          vm.encours_res.push({
            year: index_achat,
            value: (_.isArray(vm.encours_paiement[index_achat])) ? object.chiffre - vm.encours_paiement[index_achat][0].chiffre : object.chiffre
          });
        });
        console.log(vm.encours_res);
        $scope.$apply();

        $(document).ready(function () {

          am5.ready(function () {

            // Create root element
            // https://www.amcharts.com/docs/v5/getting-started/#Root_element
            var root = am5.Root.new("div_encours_barline1");
            /* var exporting = am5plugins_exporting.Exporting.new(root, {
              menu: am5plugins_exporting.ExportingMenu.new(root, {})
            });
             */
            // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/
            root.setThemes([
              am5themes_Animated.new(root)
            ]);


            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
              panX: false,
              panY: false,
              wheelX: "none",
              wheelY: "none",
              layout: root.verticalLayout
            }));


            // 
            var data = vm.encours_res;
            // var data = [{
            //   year: "2015",
            //   value: 600000
            // }, {
            //   year: "2016",
            //   value: 900000
            // }, {
            //   year: "2017",
            //   value: 180000
            // }, {
            //   year: "2018",
            //   value: 600000
            // }, {
            //   year: "2019",
            //   value: 350000
            // }, {
            //   year: "2020",
            //   value: 600000
            // }, {
            //   year: "2021",
            //   value: 670000
            // }];

            // Populate data
            for (var i = 0; i < (data.length - 1); i++) {
              data[i].valueNext = data[i + 1].value;
            }


            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
              categoryField: "year",
              renderer: am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                minGridDistance: 30
              }),
              tooltip: am5.Tooltip.new(root, {})
            }));

            xAxis.data.setAll(data);

            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
              min: 0,
              renderer: am5xy.AxisRendererY.new(root, {})
            }));


            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

            // Column series
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: "value",
              categoryXField: "year"
            }));

            series.columns.template.setAll({
              tooltipText: "{categoryX}: {valueY}",
              width: am5.percent(90),
              tooltipY: 0
            });

            series.data.setAll(data);

            // Variance indicator series
            var series2 = chart.series.push(am5xy.ColumnSeries.new(root, {
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: "valueNext",
              openValueYField: "value",
              categoryXField: "year",
              fill: am5.color(0x555555),
              stroke: am5.color(0x555555)
            }));

            series2.columns.template.setAll({
              width: 1
            });

            series2.data.setAll(data);

            series2.bullets.push(function () {
              var label = am5.Label.new(root, {
                text: "{valueY}",
                fontWeight: "500",
                fill: am5.color(0x00cc00),
                centerY: am5.p100,
                centerX: am5.p50,
                populateText: true
              });

              // Modify text of the bullet with percent
              label.adapters.add("text", function (text, target) {
                var percent = getVariancePercent(target.dataItem);
                return percent ? percent + "%" : text;
              });

              // Set dynamic color of the bullet
              label.adapters.add("centerY", function (center, target) {
                return getVariancePercent(target.dataItem) < 0 ? 0 : center;
              });

              // Set dynamic color of the bullet
              label.adapters.add("fill", function (fill, target) {
                return getVariancePercent(target.dataItem) < 0 ? am5.color(0xcc0000) : fill;
              });

              return am5.Bullet.new(root, {
                locationY: 1,
                sprite: label
              });
            });

            series2.bullets.push(function () {
              var arrow = am5.Graphics.new(root, {
                rotation: -90,
                centerX: am5.p50,
                centerY: am5.p50,
                dy: 3,
                fill: am5.color(0x555555),
                stroke: am5.color(0x555555),
                draw: function (display) {
                  display.moveTo(0, -3);
                  display.lineTo(8, 0);
                  display.lineTo(0, 3);
                  display.lineTo(0, -3);
                }
              });

              arrow.adapters.add("rotation", function (rotation, target) {
                return getVariancePercent(target.dataItem) < 0 ? 90 : rotation;
              });

              arrow.adapters.add("dy", function (dy, target) {
                return getVariancePercent(target.dataItem) < 0 ? -3 : dy;
              });

              return am5.Bullet.new(root, {
                locationY: 1,
                sprite: arrow
              })
            })


            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();
            chart.appear(1000, 100);

            //exporting data
            var exporting = am5plugins_exporting.Exporting.new(root, {
              dataSource: data,
              menu: am5plugins_exporting.ExportingMenu.new(root, {})
            });


            function getVariancePercent(dataItem) {
              if (dataItem) {
                var value = dataItem.get("valueY");
                var openValue = dataItem.get("openValueY");
                var change = value - openValue;
                return Math.round(change / openValue * 100);
              }
              return 0;
            }

          }); // end am5.ready()
        })
      })
      .then(null, function (error) {
        console.log(error);
      });
  }




  vm.initHummerSwipe = () => {
    var container = document.getElementById("container-swipe");
    var content = document.getElementById("collection");
    var hammer = new Hammer(container);

    var initialX = 0;
    var deltaX = 0;
    var offset = initialX + deltaX;

    hammer.on("panleft panright", function (ev) {
      deltaX = ev.deltaX;
      offset = initialX + deltaX;

      container.scroll(-offset, 0);
    });

    Hammer.on(container, "mouseup", function (e) {
      initialX = offset;
    });
  }

  vm.getHistoryProduct = () => {
    $('.chart_panel_line_prod').empty();
    $('.chart_panel_line_prod').append(`<div id="chartLineProductQte" style="width: 100%;height: 500px;"></div>`);
    $('.chart_panel_line_prod').append(`<h3 class="text-center">Chiffre</h3>`);
    $('.chart_panel_line_prod').append(`<div id="chartLineProductChiffre" style="width: 100%;height: 500px;"></div>`);

    WS.getData("entete_achat/getHistoryProduct", { produit_code: vm.product.code })
      .then(function (response) {
        if (response.status == 200) {
          vm.lines_product = response.data;
          vm.prods_quantites = [];
          vm.prods_chiffres = [];
          _.each(vm.lines_product, (element, index) => {

            vm.prods_quantites.push({
              year: element.date,
              value: Number(element.quantite)
            });

            vm.prods_chiffres.push({
              year: element.date,
              value: Number(element.chiffre)
            });
          })
          $(document).ready(function () {

            am5.ready(function () {

              // Create root element
              // https://www.amcharts.com/docs/v5/getting-started/#Root_element
              var root = am5.Root.new("chartLineProductQte");


              // Set themes
              // https://www.amcharts.com/docs/v5/concepts/themes/
              root.setThemes([
                am5themes_Animated.new(root)
              ]);


              // Create chart
              // https://www.amcharts.com/docs/v5/charts/xy-chart/
              var chart = root.container.children.push(am5xy.XYChart.new(root, {
               /*  panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none", */

                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",

                layout: root.verticalLayout
              }));
              // Add cursor
              // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
              /* var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none"
              }));
              cursor.lineY.set("visible", false); */


              // Data
              var data = vm.prods_quantites;
              // Populate data
              for (var i = 0; i < (data.length - 1); i++) {
                data[i].valueNext = data[i + 1].value;
              }


              // Create axes
              // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
              var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "year",
                renderer: am5xy.AxisRendererX.new(root, {
                  cellStartLocation: 0.1,
                  cellEndLocation: 0.9,
                  minGridDistance: 30
                }),
                tooltip: am5.Tooltip.new(root, {})
              }));

              xAxis.data.setAll(data);

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                min: 0,
                renderer: am5xy.AxisRendererY.new(root, {})
              }));


              // Add series
              // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

              // Column series
              var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "year"
              }));

              series.columns.template.setAll({
                tooltipText: "{categoryX}: {valueY}",
                width: am5.percent(90),
                tooltipY: 0
              });

              series.data.setAll(data);

              // Variance indicator series
              var series2 = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "valueNext",
                openValueYField: "value",
                categoryXField: "year",
                fill: am5.color(0x555555),
                stroke: am5.color(0x555555)
              }));

              series2.columns.template.setAll({
                width: 1
              });

              series2.data.setAll(data);

              series2.bullets.push(function () {
                var label = am5.Label.new(root, {
                  text: "{valueY}",
                  fontWeight: "500",
                  fill: am5.color(0x00cc00),
                  centerY: am5.p100,
                  centerX: am5.p50,
                  populateText: true
                });

                // Modify text of the bullet with percent
                label.adapters.add("text", function (text, target) {
                  var percent = getVariancePercent(target.dataItem);
                  return percent ? percent + "%" : text;
                });

                // Set dynamic color of the bullet
                label.adapters.add("centerY", function (center, target) {
                  return getVariancePercent(target.dataItem) < 0 ? 0 : center;
                });

                // Set dynamic color of the bullet
                label.adapters.add("fill", function (fill, target) {
                  return getVariancePercent(target.dataItem) < 0 ? am5.color(0xcc0000) : fill;
                });

                return am5.Bullet.new(root, {
                  locationY: 1,
                  sprite: label
                });
              });

              series2.bullets.push(function () {
                var arrow = am5.Graphics.new(root, {
                  rotation: -90,
                  centerX: am5.p50,
                  centerY: am5.p50,
                  dy: 3,
                  fill: am5.color(0x555555),
                  stroke: am5.color(0x555555),
                  draw: function (display) {
                    display.moveTo(0, -3);
                    display.lineTo(8, 0);
                    display.lineTo(0, 3);
                    display.lineTo(0, -3);
                  }
                });

                arrow.adapters.add("rotation", function (rotation, target) {
                  return getVariancePercent(target.dataItem) < 0 ? 90 : rotation;
                });

                arrow.adapters.add("dy", function (dy, target) {
                  return getVariancePercent(target.dataItem) < 0 ? -3 : dy;
                });

                return am5.Bullet.new(root, {
                  locationY: 1,
                  sprite: arrow
                })
              })

                // Add scrollbar
                // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
                var scrollbar = chart.set("scrollbarX", am5xy.XYChartScrollbar.new(root, {
                  orientation: "horizontal",
                  height: 60
                }));

                var sbDateAxis = scrollbar.chart.xAxes.push(am5xy.DateAxis.new(root, {
                  baseInterval: {
                    timeUnit: "day",
                    count: 1
                  },
                  renderer: am5xy.AxisRendererX.new(root, {})
                }));

                var sbValueAxis = scrollbar.chart.yAxes.push(
                  am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {})
                  })
                );

                var sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(root, {
                  valueYField: "value",
                  valueXField: "date",
                  xAxis: sbDateAxis,
                  yAxis: sbValueAxis
                }));


              // Make stuff animate on load
              // https://www.amcharts.com/docs/v5/concepts/animations/
              series.appear();
              chart.appear(1000, 100);


              function getVariancePercent(dataItem) {
                if (dataItem) {
                  var value = dataItem.get("valueY");
                  var openValue = dataItem.get("openValueY");
                  var change = value - openValue;
                  return Math.round(change / openValue * 100);
                }
                return 0;
              }

            }); // end am5.ready()


            /**
             * par chiffre
             */
             am5.ready(function () {

              // Create root element
              // https://www.amcharts.com/docs/v5/getting-started/#Root_element
              var root = am5.Root.new("chartLineProductChiffre");


              // Set themes
              // https://www.amcharts.com/docs/v5/concepts/themes/
              root.setThemes([
                am5themes_Animated.new(root)
              ]);


              // Create chart
              // https://www.amcharts.com/docs/v5/charts/xy-chart/
              var chart = root.container.children.push(am5xy.XYChart.new(root, {
               /*  panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none", */

                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",

                layout: root.verticalLayout
              }));
              // Add cursor
              // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
              /* var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "none"
              }));
              cursor.lineY.set("visible", false); */


              // Data
              var data = vm.prods_chiffres;
              // Populate data
              for (var i = 0; i < (data.length - 1); i++) {
                data[i].valueNext = data[i + 1].value;
              }


              // Create axes
              // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
              var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "year",
                renderer: am5xy.AxisRendererX.new(root, {
                  cellStartLocation: 0.1,
                  cellEndLocation: 0.9,
                  minGridDistance: 30
                }),
                tooltip: am5.Tooltip.new(root, {})
              }));

              xAxis.data.setAll(data);

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                min: 0,
                renderer: am5xy.AxisRendererY.new(root, {})
              }));


              // Add series
              // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

              // Column series
              var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "year"
              }));

              series.columns.template.setAll({
                tooltipText: "{categoryX}: {valueY}",
                width: am5.percent(90),
                tooltipY: 0
              });

              series.data.setAll(data);

              // Variance indicator series
              var series2 = chart.series.push(am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "valueNext",
                openValueYField: "value",
                categoryXField: "year",
                fill: am5.color(0x555555),
                stroke: am5.color(0x555555)
              }));

              series2.columns.template.setAll({
                width: 1
              });

              series2.data.setAll(data);

              series2.bullets.push(function () {
                var label = am5.Label.new(root, {
                  text: "{valueY}",
                  fontWeight: "500",
                  fill: am5.color(0x00cc00),
                  centerY: am5.p100,
                  centerX: am5.p50,
                  populateText: true
                });

                // Modify text of the bullet with percent
                label.adapters.add("text", function (text, target) {
                  var percent = getVariancePercent(target.dataItem);
                  return percent ? percent + "%" : text;
                });

                // Set dynamic color of the bullet
                label.adapters.add("centerY", function (center, target) {
                  return getVariancePercent(target.dataItem) < 0 ? 0 : center;
                });

                // Set dynamic color of the bullet
                label.adapters.add("fill", function (fill, target) {
                  return getVariancePercent(target.dataItem) < 0 ? am5.color(0xcc0000) : fill;
                });

                return am5.Bullet.new(root, {
                  locationY: 1,
                  sprite: label
                });
              });

              series2.bullets.push(function () {
                var arrow = am5.Graphics.new(root, {
                  rotation: -90,
                  centerX: am5.p50,
                  centerY: am5.p50,
                  dy: 3,
                  fill: am5.color(0x555555),
                  stroke: am5.color(0x555555),
                  draw: function (display) {
                    display.moveTo(0, -3);
                    display.lineTo(8, 0);
                    display.lineTo(0, 3);
                    display.lineTo(0, -3);
                  }
                });

                arrow.adapters.add("rotation", function (rotation, target) {
                  return getVariancePercent(target.dataItem) < 0 ? 90 : rotation;
                });

                arrow.adapters.add("dy", function (dy, target) {
                  return getVariancePercent(target.dataItem) < 0 ? -3 : dy;
                });

                return am5.Bullet.new(root, {
                  locationY: 1,
                  sprite: arrow
                })
              })

                // Add scrollbar
                // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
                var scrollbar = chart.set("scrollbarX", am5xy.XYChartScrollbar.new(root, {
                  orientation: "horizontal",
                  height: 60
                }));

                var sbDateAxis = scrollbar.chart.xAxes.push(am5xy.DateAxis.new(root, {
                  baseInterval: {
                    timeUnit: "day",
                    count: 1
                  },
                  renderer: am5xy.AxisRendererX.new(root, {})
                }));

                var sbValueAxis = scrollbar.chart.yAxes.push(
                  am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {})
                  })
                );

                var sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(root, {
                  valueYField: "value",
                  valueXField: "date",
                  xAxis: sbDateAxis,
                  yAxis: sbValueAxis
                }));


              // Make stuff animate on load
              // https://www.amcharts.com/docs/v5/concepts/animations/
              series.appear();
              chart.appear(1000, 100);


              function getVariancePercent(dataItem) {
                if (dataItem) {
                  var value = dataItem.get("valueY");
                  var openValue = dataItem.get("openValueY");
                  var change = value - openValue;
                  return Math.round(change / openValue * 100);
                }
                return 0;
              }

            }); // end am5.ready()

          });
          $scope.$apply();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }



  vm.openModalReglement = () => {
    vm.modal_add_reglement = $uibModal.open({
                                  animation: true,
                                  templateUrl: 'modalAddReglement',
                                  size: 'lg',
                                  backdrop: true,
                                  controller: function($scope, $translate) {
                                    $scope.reglement = {};
                                    $scope.reglement.reste = $scope.reglement.montant;

                                      $scope.submitReglement = function() {
                                        WS.post("entete_achat/addNewReglement", $scope.reglement )
                                                .then(function (response) {
                                                  if (response.data.success) {
                                                    swal(
                                                      $translate.instant("Succès"),
                                                      $translate.instant("Reglement ajoutée avec Succès"),
                                                      "success"
                                                    )
                                                      .then(function () {
                                                        vm.modal_add_reglement.close();
                                                      })
                                                      .catch(function (error) {
                                                        vm.modal_add_reglement.close();
                                                      });

                                                      vm.list_reglements.unshift($scope.reglement);
                                                      $scope.$apply();
                                                  }
                                                })
                                                .then(null, function (error) {
                                                  console.log(error);
                                                });
                                      }
                                  }
                              });
  }

  vm.search_reglement = {};
  vm.getListReglement = () => {
    WS.getData("entete_achat/getListReglement", vm.search_reglement)
      .then(function (response) {
        if (response.status == 200) {
          vm.list_reglements = response.data;
          $scope.$apply();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.tab_selected_reglements = [];
  vm.manage_solde = (reglement) => {
    if(reglement.selected==1){
      vm.tab_selected_reglements.push(reglement);
    }else{
      vm.tab_selected_reglements = vm.tab_selected_reglements.filter(function(item) {
          return item.code !== reglement.code
      });
    }
    console.log(vm.tab_selected_reglements);
  }


  vm.paiements_selected = 0;
  $scope.$watch("vm.tab_selected_reglements", function(newvalue) {
      console.log('watched' , newvalue);
      vm.paiements_selected= vm.tab_selected_reglements.reduce((s, f) => {
          return Number(s) + Number(f.reste);              
      }, 0);
  }, true); 

}
export default {
  name: "achatController",
  fn: achatController,
};
